import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { SidebarContext } from '../../util/context/SidebarContext'
import ImageWithBasePath from '../../components/general/ImageCustom'
import UserService from '../../core/services/auth'
import ButtonLoading from '../../components/general/ButtonLoading'
import { useNavigate } from 'react-router-dom'
import { showErrorMessage, showSuccessMessage } from '../../util/util'

const ForgotPassword = () => {
  const { isLoading } = useContext(SidebarContext)
  const { register, handleSubmit } = useForm()
  const navigate = useNavigate()

  const onSubmit = (data) => {
    UserService.forgotPassword(data)
      .then((res) => {
        showSuccessMessage('Forgot password successfully')
      })
      .catch((err) => {
        showErrorMessage(err?.detail || 'Forgot password failed')
      })
  }

  return (
    <div className="w-full h-screen">
      <div className="relative w-full h-full">
        <ImageWithBasePath
          src={'assets/img/authentication/signin-bg.png'}
          className="object-cover w-full h-full"
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="absolute inset-0 flex justify-center items-center">
          <div className="flex flex-col gap-[64px] bg-white p-10 shadow-lg rounded-md w-[479px]">
            <div>
              <div className='flex flex-col items-center gap-3'>
                <ImageWithBasePath
                  src={'assets/img/forgot-password/lock.svg'}
                  className="object-cover w-[32px] h-[40px]"
                />
                <div className='text-[20px] text-center font-bold text-[rgb(38,38,38)]'>Quên mật khẩu</div>
              </div>
              <div className='flex flex-col gap-3'>
                <div className='text-sm text-center text-[rgb(38,38,38)]'>
                  Nhập email và ATOM sẽ gửi cho bạn một liên kết để truy cập lại vào tài khoản.
                </div>
                <input type="text"  {...register('email')} className='border-[1px] border-solid w-full border-[rgba(217,217,217,1)] py-[14px] pl-4 text-[14px] font-normal rounded' placeholder='Vui lòng nhập email' />
              </div>
            </div>
            <div className='flex flex-col gap-3'>
              <ButtonLoading loading={isLoading} content={'Gửi liên kết'} className='text-[16px] rounded font-semibold py-3 px-4 text-[rgba(255,255,255,1)]' />
              <ButtonLoading type='button' func={() => navigate('/signin')} content={'Trở lại'} className='text-[16px] rounded font-semibold py-3 text-[#525252] px-4 bg-transparent border-[1px] border-solid border-[#FF505B]' />
            </div>

          </div>
        </form>
      </div>
    </div>
  )
}

export default ForgotPassword
