import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import RestaurantService from '../../core/services/RestaurantService'
import KitchenDisplay from './kitchenDisplay'

const Kitchen = () => {
  const { t } = useTranslation()
  const MySwal = withReactContent(Swal)
  const { kitchenId } = useParams()
  const [kitchenDisplay, setKitchenDisplay] = useState({})
  const [stages, setStages] = useState([])
  const [orders, setOrders] = useState([])
  // Filter orders by stage
  useEffect(() => {
    const fetchData = async () => {
      const [kitchenDisplayData] = await Promise.all([RestaurantService.getPreparationDisplayData(kitchenId)])
      if (kitchenDisplayData) {
        setKitchenDisplay(kitchenDisplayData)
        setStages(kitchenDisplayData.stages)
        setOrders(kitchenDisplayData.orders)
      }
    }
    if (kitchenId) {
      fetchData()
    }
  }, [kitchenId])
  return (
    <div>
      {kitchenDisplay ? (
        <KitchenDisplay
          stages={stages}
          orders={orders}
          kitchenId={kitchenId}
          setKitchenDisplay={setKitchenDisplay}
          setStages={setStages}
          setOrders={setOrders}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default Kitchen
