// import qs from "qs";
/* eslint-disable no-unused-vars */
import requests from './httpService'
// import Cookies from "js-cookie";
import { callPath } from './endpoint'

const preparationStage = {
    'To prepare': 4,
    'Ready': 5,
    'Completed': 6,
}

const RestaurantService = {
    getAllPreparationDisplay: async (context_company) => {
        const jsonData = {
            model: 'pos_preparation_display.display',
            method: 'web_search_read',
            with_context: { allowed_company_ids: context_company },
            kwargs: {
                domain: [],
                specification: {
                    id: {},
                    display_name: {},
                    order_count: {},
                    pos_config_ids: {
                        fields: {
                            name: {}
                        }
                    },
                },
            },
        }

        return requests.post(callPath, jsonData, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },

    getPreparationDisplayByPosConfigId: async (posConfigId) => {
        const jsonData = {
            model: 'pos_preparation_display.display',
            method: 'web_search_read',
            kwargs: {
                domain: [["pos_config_ids", "=", parseInt([posConfigId])]],
                specification: {
                    id: {},
                    display_name: {},
                    order_count: {},
                },
            },
        }

        return requests.post(callPath, jsonData, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },


    getPreparationDisplayData: async (preparationDisplayId) => {
        const jsonData = {
            "model": "pos_preparation_display.display",
            "method": "get_preparation_display_data",
            "ids": parseInt(preparationDisplayId)
        }

        return requests.post(callPath, jsonData, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },

    processOrderToPreparation: async (orderId) => {
        const jsonData = {
            "model": "pos_preparation_display.order",
            "method": "process_order",
            "args": [parseInt(orderId), false, {}]
        }
        return requests.post(callPath, jsonData, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },

    changePreparationStage: async (orderId, preparationDisplayId, stageId) => {
        const jsonData = {
            "model": "pos_preparation_display.order",
            "method": "change_order_stage",
            "ids": parseInt(orderId),
            "args": [parseInt(stageId), parseInt(preparationDisplayId)]
        }
        return requests.post(callPath, jsonData, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },

    donePreparationStage: async (orderId, preparationDisplayId) => {
        const jsonData = {
            "model": "pos_preparation_display.order",
            "method": "done_orders_stage",
            "ids": orderId,
            "args": [parseInt(preparationDisplayId)]
        }
        return requests.post(callPath, jsonData, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    }

}

export default RestaurantService
