import React from 'react'
import { Controller } from 'react-hook-form'
import Select from 'react-select'
import ViewServices from '../../core/services/view-service/view-service'
import { evalJSONDomain } from '../../util/util'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import InfoWidget from './Info'
import useHeaderStore from '../../store/header'

const Many2ManyTag = ({
  relation,
  value,
  name,
  methods,
  readonly,
  placeholder,
  required,
  invisible,
  defaultValue,
  isForm,
  handleOnchange,
  domain
}) => {
  const { t } = useTranslation()
  const { rootContext } = useHeaderStore()

  const domainObject = evalJSONDomain(domain, methods?.getValues() || {})
  const { data: dataOfSelection } = useQuery({
    queryKey: [`data_${relation}`, domainObject],
    queryFn: () => ViewServices.getSelectionItem({ model: relation, domain: domainObject, context: rootContext })
  }
  )

  const customNoOptionsMessage = () => t("no_option")
  const tranfer = (data) => {
    return data?.map((val) => ({
      id: val.value,
      display_name: val.label,
    })) || []
  }

  const options =
    dataOfSelection?.records?.map((val) => ({
      value: val.id,
      label: val.name ?? val.display_name,
      ...val
    })) || []

  // const optionValue = options.filter((option) => {
  //   if (typeof value === 'string') {
  //     return value?.split(",")?.map(item => (item) === option.label)
  //   } else if (typeof value === 'array') {
  //     return value?.map(item => (item?.id) === option.value)
  //   }
  // })
  const optionValue = value?.map(item => ({ ...item, value: item.id, label: item.name ?? item.display_name }))

  if (!isForm) {
    // return <Select
    //   key={JSON.stringify(optionValue)}
    //   options={options}
    //   isMulti
    //   value={optionValue || null}
    //   className='col-span-2 mb-2'
    //   noOptionsMessage={customNoOptionsMessage}
    //   isDisabled={true}
    //   placeholder={placeholder}
    //   required={!invisible && required}
    //   readonly={true}
    //   classNames={'custom-select'}
    // />
    return <InfoWidget value={optionValue || null} />
  } else {
    console.log("value tag", options);

    return (
      <Controller
        name={name}
        control={methods.control}
        render={({ field }) => {
          return <Select
            key={field?.value}
            options={options}
            noOptionsMessage={customNoOptionsMessage}
            isMulti
            value={options.filter((option) => (field.value ??= [])?.some(item => (item?.id) === option.value)) || null}
            onChange={(selectedOptions) => {
              field.onChange(tranfer(selectedOptions))
              handleOnchange(name, tranfer(selectedOptions))
            }}
            classNames={{
              control: () => isForm ? "field h-auto custom-select" : "field-in-tree custom-select"
            }}
            // isDisabled={readonly}
            placeholder={t("choose_place")}
            required={!invisible && required}
          />
        }}
      />
    )
  }
}

export default Many2ManyTag
