// import qs from "qs";
import requests from "./httpService";
// import Cookies from "js-cookie";
import { callPath, searchPath } from "./endpoint";
import { subMonths } from "date-fns";
// import { useState } from "react";

const SaleDashboardService = {
   reportRevenue: async (dayStart, dayEnd) => {
      const jsonData = {
         model: "sale.report",
         method: "read_group",
         kwargs: {
            domain: [
               "&",
               ["state", "not in", ["draft", "sent", "cancel"]],
               "&",
               ["date", ">=", dayStart],
               ["date", "<=", dayEnd],
            ],
            fields: ["__count", "price_subtotal:sum"],
            groupby: ["date:month"],
            lazy: false,
         },
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   reportRevenuePercentPart: async (dayStart, dayEnd) => {
      const thisMonthStart = new Date(dayStart);
      thisMonthStart.setDate(1);
      thisMonthStart.setHours(0);
      thisMonthStart.setMinutes(0);
      thisMonthStart.setSeconds(0);
      const thisMonthEnd = new Date(dayEnd);
      const beforeMonthStart = subMonths(thisMonthStart, 1);
      const beforeMonthEnd = subMonths(thisMonthEnd, 1);
      const jsonDataBefore = {
         model: "sale.report",
         method: "read_group",
         kwargs: {
            domain: [
               "&",
               ["state", "not in", ["draft", "sent", "cancel"]],
               "&",
               ["date", ">=", beforeMonthStart],
               ["date", "<=", beforeMonthEnd],
            ],
            fields: ["__count", "price_subtotal:sum"],
            groupby: ["date:month"],
            lazy: false,
         },
      };

      const jsonDataAfter = {
         model: "sale.report",
         method: "read_group",
         kwargs: {
            domain: [
               "&",
               ["state", "not in", ["draft", "sent", "cancel"]],
               "&",
               ["date", ">=", thisMonthStart],
               ["date", "<=", thisMonthEnd],
            ],
            fields: ["__count", "price_subtotal:sum"],
            groupby: ["date:month"],
            lazy: false,
         },
      };
      const revenueBeforeResponse = await requests.post(
         callPath,
         jsonDataBefore,
         {
            headers: {
               "Content-Type": "application/json",
            },
         }
      );

      const revenueAfterResponse = await requests.post(
         callPath,
         jsonDataAfter,
         {
            headers: {
               "Content-Type": "application/json",
            },
         }
      );

      const revenueBefore = revenueBeforeResponse[0].price_subtotal;
      const revenueAfter = revenueAfterResponse[0].price_subtotal;
      const percent = ((revenueAfter - revenueBefore) / revenueBefore) * 100;
      const revenue = revenueAfter - revenueBefore;

      return { percent: percent.toFixed(2), revenue: revenue };
   },
   reportOrderReturn: async (day_start, day_end) => {
      const jsonData = {
         model: "stock.picking",
         count: true,
         domain: [
            "&",
            "&",
            ["picking_type_id", "=", 1],
            ["return_id", "!=", false],
            "&",
            ["date", ">=", day_start],
            ["date", "<=", day_end],
         ],
      };
      return requests.post(searchPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   countOrder: async (day_start, day_end) => {
      const jsonData = {
         model: "sale.order",
         count: true,
         domain: [
            ["state", "not in", ["draft", "sent", "cancel"]],
            ["date_order", ">=", day_start],
            ["date_order", "<=", day_end],
         ],
      };
      return requests.post(searchPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   getListBestSellingProduct: async (day_start, day_end) => {
      // const [data] = useState(null);
      try {
         const jsonData = {
            model: "sale.report",
            method: "read_group",
            kwargs: {
               domain: [
                  "&",
                  "&",
                  ["product_tmpl_id", "!=", false],
                  ["state", "not in", ["draft", "sent", "cancel"]],
                  "&",
                  ["date", ">=", day_start],
                  ["date", "<=", day_end],
               ],
               fields: ["order_reference:count_distinct", "price_subtotal:sum"],
               groupby: ["product_tmpl_id"],
               orderby: "price_subtotal:sum desc",
               lazy: false,
            },
         };
         const response = await requests.post(callPath, jsonData, {
            headers: {
               "Content-Type": "application/json",
            },
         });

         if (response) {
            const productDetailsPromises = response.map(async (item) => {
               const productJsonData = {
                  model: "product.template",
                  method: "web_search_read",
                  kwargs: {
                     domain: [["id", "=", item.product_tmpl_id[0]]],
                     specification: {
                        image_256: {},
                        name: {},
                     },
                  },
               };

               const response = await requests.post(callPath, productJsonData, {
                  headers: {
                     "Content-Type": "application/json",
                  },
               });

               if (response && response.records.length != 0) {
                  const productTmpl = response.records[0];
                  productTmpl.total = item.price_subtotal;
                  productTmpl.countOrder = item.order_reference;
                  return productTmpl;
               }

               return null;
            });

            // Sử dụng Promise.all để đợi tất cả các yêu cầu hoàn thành
            const productDetails = await Promise.all(productDetailsPromises);
            return productDetails.filter((item) => item !== null);
         }
      } catch (error) {
         console.error("Error fetching best selling products:", error);
      }
   },
   getListBestSellingProductByQty: async (day_start, day_end) => {
      try {
         const jsonData = {
            model: "sale.report",
            method: "read_group",
            kwargs: {
               domain: [
                  "&",
                  "&",
                  ["product_tmpl_id", "!=", false],
                  ["state", "not in", ["draft", "sent", "cancel"]],
                  "&",
                  ["date", ">=", day_start],
                  ["date", "<=", day_end],
               ],
               fields: ["product_uom_qty:sum", "product_tmpl_id"],
               groupby: ["product_tmpl_id"],
               orderby: "product_uom_qty desc",
               lazy: false,
            },
         };

         const response = await requests.post(callPath, jsonData, {
            headers: {
               "Content-Type": "application/json",
            },
         });

         if (response) {
            const productDetailsPromises = response.map(async (item) => {
               const productJsonData = {
                  model: "product.template",
                  method: "web_search_read",
                  kwargs: {
                     domain: [["id", "=", item.product_tmpl_id[0]]],
                     specification: {
                        name: {},
                     },
                  },
               };

               const response = await requests.post(callPath, productJsonData, {
                  headers: {
                     "Content-Type": "application/json",
                  },
               });

               if (response && response.records.length != 0) {
                  const productTmpl = response.records[0];
                  productTmpl.product_uom_qty = item.product_uom_qty;
                  return productTmpl;
               }

               return null;
            });

            const productDetails = await Promise.all(productDetailsPromises);
            return productDetails.filter((item) => item !== null);
         }
      } catch (error) {
         console.error("Error fetching best selling products:", error);
      }
   },
   reportRevenueByDate: async (day_start, day_end) => {
      try {
         const jsonData = {
            model: "sale.report",
            method: "read_group",
            with_context: {
               lang: "en_US",
            },
            kwargs: {
               domain: [
                  "&",
                  ["state", "not in", ["draft", "sent", "cancel"]],
                  "&",
                  ["date", ">=", day_start],
                  ["date", "<=", day_end],
               ],
               fields: ["order_reference:count_distinct", "price_subtotal:sum"],
               groupby: ["date:day"],
               lazy: false,
            },
         };
         const response = await requests.post(callPath, jsonData, {
            headers: {
               "Content-Type": "application/json",
            },
         });

         if (response) {
            const revenues = response.map((item) => {
               let date_convert = new Date(item["date:day"]);

               return {
                  date: date_convert,
                  order_reference: item.order_reference,
                  price_subtotal: item.price_subtotal,
               };
            });
            return revenues;
         }

         return [];
      } catch (error) {
         console.error("Error fetching report by date:", error);
      }
   },
};

export default SaleDashboardService;
