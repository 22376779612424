import { callPath, createPath, updatePath, uploadFilePath, companyPath } from '../../../util/constant/endpoint';
import requests from '../AxiosClient';
import UserService from '../auth/index';
const StoreService = {
  getAll: async () => {
    const jsonData = {
      model: 'res.company',
      method: 'web_search_read',
      kwargs: {
        domain: [],
        specification: {
          id: {},
          name: {},
          email: {},
          phone: {},
          display_name: {},
          logo: {},
          email_formatted: {},
          country_id: { fields: { name: {} } },
        },
      },
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },

  getAllowedStore: async () => {
    const jsonData = {
      model: 'res.company',
      method: 'web_search_read',
      kwargs: {
        domain: [['user_ids', '=', await UserService.profile().then((res) => res.sub)]],
        specification: {
          id: {},
          name: {},
          logo: {},
        },
      },
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },

  createStore: async (body) => {
    return requests.post(createPath, body, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },

  updateStore: async (body) => {
    return requests.post(updatePath, body, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },

  getStoreById: async (storeId) => {
    const jsonData = {
      model: 'res.partner',
      method: 'web_search_read',
      kwargs: {
        domain: [
          ['store_rank', '>', 0],
          ['id', '=', storeId],
        ],
        specification: {
          supplier_rank: {},
          store_rank: {},
          user_ids: {},
          bank_ids: {
            fields: {
              id: {},
              acc_number: {},
              acc_holder_name: {},
              bank_id: { fields: { display_name: {} } },
            },
          },
          name: {},
          type: {},
          contact_address: {},
          date: {},
          id: {},
          phone: {},
          email: {},
          image_256: {},
          complete_name: {},
        },
      },
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },

  setImage: async (body, queryParams) => {
    const query = new URLSearchParams(queryParams).toString()
    return requests.post(uploadFilePath + '?' + query, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  archiveStore: async (storeId) => {
    const jsonData = {
      model: 'res.company',
      method: 'action_archive',
      ids: storeId,
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },

  switchCurrentUserCompany: async (companyId) => {
    const jsonData = {
      model: 'res.users',
      domain: [
        // ["id", "=", await UserService.profile().then((res) => res.sub)],
      ],
      values: {
        company_id: parseInt(companyId),
      },
    }
    return requests.post(updatePath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },

  getCurrentCompany: async () => {
    return requests.get(companyPath, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },

  getUserCompany: async (id) => {
    const jsonData = {
      model: 'res.company',
      method: 'web_search_read',
      kwargs: {
        domain: [['id', '=', id]],
        specification: {
          primary_color: {},

          secondary_color: {},

          logo: {},

          display_name: {},
        },
      },
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },

  getUserCompanyByUserId: async () => {
    const jsonData = {
      model: "res.users",
      method: "web_search_read",
      kwargs: {
        domain: [
          [
            "user_ids",
            "=",
            await UserService.profile().then((res) => res.sub), //
          ],
        ],
        specification: {
          id: {},
          company_id: { fields: { name: {}, logo: {} } },
          company_ids: { fields: { name: {} } },
        },
      },
    };
    return requests.post(callPath, jsonData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
};

export default StoreService
