import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ImageWithBasePath from '../../components/general/ImageCustom'
import { ArrowLeft, ChevronLeft, ChevronRight, Edit, MoreVertical, PlusCircle, Trash2, X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import RestaurantCreateTable from './restaurantCreateTable'
import RestaurantCreateFloor from './restaurantCreateFloor'
import POSService from '../../core/services/POSService'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Button } from 'react-bootstrap'

const RestaurantTable = ({
  floors,
  handleSelectTable,
  handleChangeTable,
  isUpdate,
  setFloor,
  posId,
  tableHasOrder,
  posConfigId,
  showConfirmationAlertCloseSession,
}) => {
  const MySwal = withReactContent(Swal)

  const [selectFloor, setSelectFloor] = useState(floors[0]?.id)
  const [tables, setTables] = useState([])
  const [updateTable, setUpdateTable] = useState({})
  const { t } = useTranslation()
  // Calculate total tables across all floors
  const totalTables = floors?.reduce((acc, floor) => acc + floor.table_ids.length, 0) || 0

  // pagination
  console.log(selectFloor)
  console.log(tableHasOrder, 'tableHasOrder')

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 8 // Number of items to display per page
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = tables ? tables.slice(indexOfFirstItem, indexOfLastItem) : []
  const totalPages = tables ? Math.ceil(tables.length / itemsPerPage) : 0

  const showConfirmationAlertDeleteTable = (tableId) => {
    MySwal.fire({
      title: t('confirm_delete_table'),
      showCancelButton: true,
      confirmButtonColor: '#00ff00',
      confirmButtonText: t('confirm_yes'),
      cancelButtonColor: '#ff0000',
      cancelButtonText: t('confirm_no'),
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: t('notify_remove_table'),
          className: 'btn btn-success',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
        handleDeleteTable(tableId)
      } else {
        MySwal.close()
      }
    })
  }

  const showConfirmationAlertDeleteFloor = (floorId) => {
    MySwal.fire({
      title: t('confirm_delete_floor'),
      showCancelButton: true,
      confirmButtonColor: '#00ff00',
      confirmButtonText: t('confirm_yes'),
      cancelButtonColor: '#ff0000',
      cancelButtonText: t('confirm_no'),
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: t('notify_remove_floor'),
          className: 'btn btn-success',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
        handleDeleteFloor(floorId)
      } else {
        MySwal.close()
      }
    })
  }

  // Generate page numbers array
  const getPageNumbers = () => {
    const pageNumbers = []
    const maxVisiblePages = 5
    const halfVisible = Math.floor(maxVisiblePages / 2)

    let startPage = Math.max(1, currentPage - halfVisible)
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1)

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1)
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i)
    }

    return pageNumbers
  }

  const handleSelectFloor = (floor) => {
    setSelectFloor(floor)
    setCurrentPage(1)
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const handleDeleteTable = async (tableId) => {
    await POSService.deleteTable(tableId).then((res) => {
      POSService.getListPOSById(posId).then((res) => {
        setFloor(res.records[0].floor_ids)
      })
    })
  }

  const handleDeleteFloor = async (floorId) => {
    await POSService.deleteFloor(floorId).then((res) => {
      POSService.getListPOSById(posId).then((res) => {
        setFloor(res.records[0].floor_ids)
      })
    })
  }

  useEffect(() => {

    setTables(floors.find((floor) => floor.id === selectFloor)?.table_ids)
    console.log(tables, 'tables')
  }, [floors, selectFloor])

  return (
    <div>
      <div className='page-wrapper-pos fade-in p-5'>
        <div s className='content'>
          <div className='card'>
            <div className='card-body'>
              <div className='table-top table-top-two table-top-new'>
                <div className='container-fluid'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='total-employees'>
                      <h6>
                        {t('total_tables')} <span>{totalTables}</span>
                      </h6>
                    </div>
                    <div className='row custom-pagination'>
                      <div className='col-md-12'>
                        <div className='paginations d-flex justify-content-center'>
                          <span>
                            <i className='fas fa-chevron-left' />
                          </span>
                          <ul className='d-flex align-items-center page-wrap'>
                            {floors.map((floor) => {
                              return (
                                <li key={floor.id}>
                                  <Link
                                    to='#'
                                    onClick={(e) => {
                                      e.preventDefault()
                                      handleSelectFloor(floor.id)
                                    }}
                                    className={`${selectFloor === floor.id ? 'active' : ''}`}
                                  >
                                    {floor.name}
                                  </Link>
                                </li>
                              )
                            })}
                            {!isUpdate && (
                              <li>
                                <Link
                                  onClick={(e) => {
                                    e.preventDefault()
                                  }}
                                  data-bs-toggle='modal'
                                  data-bs-target='#create-new-floor'
                                >
                                  <PlusCircle className='' />
                                </Link>
                              </li>
                            )}
                          </ul>
                          <span>
                            <i className='fas fa-chevron-right' />
                          </span>
                        </div>
                      </div>
                    </div>
                    {!isUpdate && (
                      <div
                        className='dropdown pos-menu-action'
                        onClick={(e) => {
                          e.preventDefault()
                        }}
                      >
                        <Link
                          className='dropdown-toggle'
                          data-bs-toggle='dropdown'
                          aria-expanded='false'
                          onClick={(e) => {
                            e.preventDefault()
                          }}
                        >
                          <MoreVertical />
                        </Link>
                        <ul className='dropdown-menu'>
                          <li>
                            <Link
                              to='#'
                              className='dropdown-item'
                              onClick={(e) => {
                                e.preventDefault()
                              }}
                              data-bs-toggle='modal'
                              data-bs-target='#update-floor'
                            >
                              <span className='d-flex align-items-center'>
                                <Edit className='feather-16 mr-2' />
                                {t('edit_floor')}
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to='#'
                              className='dropdown-item confirm-text mb-0'
                              onClick={(e) => {
                                e.preventDefault()
                                showConfirmationAlertDeleteFloor(selectFloor)
                              }}
                            >
                              <span className='d-flex align-items-center me-1'>
                                <Trash2 className='feather-16 mr-2' />
                                {t('delete_floor')}
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to='#'
                              className='dropdown-item'
                              onClick={(e) => {
                                showConfirmationAlertCloseSession()
                                e.preventDefault()
                              }}
                            >
                              <span className='d-flex align-items-center me-1'>
                                <X className='feather-16 mr-2' />
                                {t('close_session')}
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to='/pos-session' className='dropdown-item'>
                              <span className='d-flex align-items-center me-1'>
                                <ArrowLeft className='feather-16 mr-2' />
                                {t('back')}
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>


                </div>
              </div>
            </div>
          </div>

          <div className='employee-grid-widget mt-4'>
            <div className='grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
              {currentItems?.map((table) => (
                <div key={table.id} className='employee-grid-profile' data-bs-dismiss={isUpdate ? 'modal' : undefined}>
                  <div className='profile-head d-flex justify-content-between'>
                    <div className='profile-head-action'>
                      <div>
                        {tableHasOrder?.includes(table.id) ? (
                          <span className='badge badge-linedanger me-1 w-auto text-center'>{t('has_order')}</span>
                        ) : (
                          <span className='badge badge-linesuccess me-1 w-auto text-center'>{t('free_table')}</span>
                        )}
                      </div>
                    </div>
                    {!isUpdate && (
                      <div
                        className='dropdown pos-menu-action'
                        onClick={(e) => {
                          e.preventDefault()
                        }}
                      >
                        <Link
                          className='action-icon dropdown-toggle'
                          data-bs-toggle='dropdown'
                          aria-expanded='false'
                          onClick={(e) => {
                            e.preventDefault()
                          }}
                        >
                          <MoreVertical />
                        </Link>
                        <ul className='dropdown-menu'>
                          <li>
                            <Link
                              to='#'
                              className='dropdown-item'
                              onClick={(e) => {
                                e.preventDefault()
                                setUpdateTable(table)
                              }}
                              data-bs-toggle='modal'
                              data-bs-target='#update-table'
                            >
                              <span className='d-flex align-items-center me-1'>
                                <Edit className='feather-16 mr-2' />
                                {t('edit_table')}
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to='#'
                              className='dropdown-item confirm-text mb-0'
                              onClick={(e) => {
                                e.preventDefault()
                                showConfirmationAlertDeleteTable(table.id)
                              }}
                            >
                              <span className='d-flex align-items-center me-1'>
                                <Trash2 className='feather-16 mr-2' />
                                {t('delete_table')}
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className='profile-info'>
                    <div className='profile-pic active-profile'>
                      <ImageWithBasePath src='https://cdn-icons-png.flaticon.com/512/6175/6175089.png' alt='' />
                    </div>
                    <h4 className='font-semibold'>
                      {t('table')} : {table.name}
                    </h4>
                  </div>
                  <div className='department'>
                    <div className='d-flex justify-content-center'>

                    </div>
                  </div>
                  <div style={{ marginTop: '10px' }} className='d-flex justify-content-center'>
                    <Button
                      onClick={(e) => {
                        e.preventDefault()
                        isUpdate ? handleChangeTable(table.id) : handleSelectTable(table.id)
                      }}
                    >
                      {t('open')}
                    </Button>
                  </div>
                </div>
              ))}

              {currentItems.length < 8 && !isUpdate && (
                <div
                  className='d-flex justify-content-center align-items-center'
                  data-bs-toggle='modal'
                  data-bs-target='#create-new-table'
                >
                  <label className='flex h-32 w-32 cursor-pointer items-center justify-center rounded-lg border-4 border-dashed border-gray-300 bg-white transition-colors duration-300 hover:bg-gray-200'>
                    <ImageWithBasePath src='assets/img/add-icon.svg' alt='add-icon' className='h-12 w-12 opacity-50' />
                  </label>
                </div>
              )}
            </div>
          </div>

          {/* Pagination */}
          {totalPages > 1 && (
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='mb-3 mt-4 flex items-center justify-center gap-2'>
                    <button
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                      className='rounded p-2 hover:bg-gray-100 disabled:opacity-50'
                    >
                      <ChevronLeft className='h-5 w-5' />
                    </button>

                    {getPageNumbers().map((number) => (
                      <button
                        key={number}
                        onClick={() => handlePageChange(number)}
                        className={`rounded px-3 py-1 ${currentPage === number ? 'bg-blue-500 text-white' : 'hover:bg-gray-100'
                          }`}
                      >
                        {number}
                      </button>
                    ))}

                    <button
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                      className='rounded p-2 hover:bg-gray-100 disabled:opacity-50'
                    >
                      <ChevronRight className='h-5 w-5' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <RestaurantCreateTable floorId={selectFloor} setFloor={setFloor} posId={posId} updateTable={updateTable} />
          <RestaurantCreateFloor
            posConfigId={posConfigId}
            setFloor={setFloor}
            posId={posId}
            updateFloor={selectFloor}
          />
        </div>
      </div>
    </div>
  )
}

export default RestaurantTable
