import React from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";

const ConfirmModal = ({
  title,
  content,
  onOkFunc,
  onCancelFunc,
  open,
  textOk,
  textCancel,
}) => {
  return (
    <Modal
      title={title}
      open={open}
      onOk={onOkFunc}
      onCancel={onCancelFunc}
      okText={textOk}
      cancelText={textCancel}
    >
      {content}
    </Modal>
  );
};

ConfirmModal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  onOkFunc: PropTypes.func.isRequired,
  onCancelFunc: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  textOk: PropTypes.string,
  textCancel: PropTypes.string,
};

export default ConfirmModal;
