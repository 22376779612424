import {
   Calendar,
   ChevronUp,
   RotateCcw,
} from "feather-icons-react/build/IconComponents";
import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import Chart from "react-apexcharts";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import "bootstrap-daterangepicker/daterangepicker.css";
// import DateRangePicker from "react-bootstrap-daterangepicker";
import ImageWithBasePath from "../../components/general/ImageCustom";
import { Link } from "react-router-dom";
import { Tooltip } from "react-bootstrap";
import SaleDashboardService from "../../core/services/SaleDashboardService";
import { useTranslation } from "react-i18next";
import { formatPrice, formatQuantity } from "../../core/utils/formatter";
import { ChevronDown } from "react-feather";
import Loading from "../../components/general/Loading";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

const SalesDashboard = () => {
   const navigate = useNavigate();
   const location = useLocation();
   let searchParams = new URLSearchParams(location.search);
   const { t } = useTranslation();

   let todayStart = new Date();
   todayStart.setHours(0);
   todayStart.setMinutes(0);
   todayStart.setSeconds(0);
   let todayEnd = new Date();
   todayEnd.setHours(23);
   todayEnd.setMinutes(59);
   todayEnd.setSeconds(59);

   const [saleDissect, setSaleDissect] = useState(
      searchParams.get("saleDissect") ? searchParams.get("saleDissect") : 0
   );
   const [bestSellingType, setbestSellingType] = useState(
      searchParams.get("bestSellingType")
         ? searchParams.get("bestSellingType")
         : 0
   );
   const [bestSellingDate, setbestSellingDate] = useState(
      searchParams.get("bestSellingDate")
         ? searchParams.get("bestSellingDate")
         : 0
   );
   const [valueBestSelling, setValueBestSelling] = useState(
      t("the_last_3_days")
   );
   const [valueAnalytis, setValueAnalytis] = useState(t("the_last_3_days"));
   const [valueTypeBestSelling, setValueTypeBestSelling] = useState(
      t("by_revenue")
   );
   const [valueSaleResult, setValueSaleResult] = useState(
      t("value_report_today")
   );
   const [titleSaleResult, setTitleValueSaleResult] = useState(
      t("report_today")
   );
   const [typeSaleResult, setTypeSaleResult] = useState(
      searchParams.get("typeSaleResult")
         ? searchParams.get("typeSaleResult")
         : 0
   );

   const [dayRevenueStart, setDayRevenueStart] = useState(null);
   const [dayRevenueEnd, setDayRevenueEnd] = useState(null);
   const [dayStart, setDayStart] = useState(todayStart);
   const [dayEnd, setDayEnd] = useState(todayEnd);

   const [partDayStart, setPartDayStart] = useState(null);
   const [partDayEnd, setPartDayEnd] = useState(null);

   const [cacheKeySelling, setCacheKeySelling] = useState(null);
   const [titleBestSale, setTitleBestSale] = useState(t("revenue_title"));

   // let formatString = 'yyyy-MM-dd HH:mm:ss';
   const getLastDayOfMonth = (year, month) => {
      const date = new Date(year, month + 1, 0);
      return date.getDate();
   };

   // type sale report
   useEffect(() => {
      if (!searchParams.has("typeSaleResult")) {
         searchParams.append("typeSaleResult", typeSaleResult);
      } else {
         searchParams.set("typeSaleResult", typeSaleResult);
      }
      navigate({
         pathname: location.pathname,
         search: searchParams.toString(),
      });

      if (typeSaleResult == 0) {
         setValueSaleResult("value_report_today");
         setTitleValueSaleResult("report_today");
         setDayStart(todayStart);
         setDayEnd(todayEnd);
      } else if (typeSaleResult == 1) {
         setValueSaleResult("value_report_lastday");
         setTitleValueSaleResult("report_lastday");
         const dayStartClone = new Date(
            todayStart.getFullYear(),
            todayStart.getMonth(),
            todayStart.getDate() - 1,
            0,
            0,
            0
         );
         const dayEndClone = new Date(
            todayEnd.getFullYear(),
            todayEnd.getMonth(),
            todayEnd.getDate() - 1,
            23,
            59,
            59
         );
         setDayStart(dayStartClone);
         setDayEnd(dayEndClone);
      }
   }, [typeSaleResult]);

   // type date report revenue
   useEffect(() => {
      if (!searchParams.has("saleDissect")) {
         searchParams.append("saleDissect", saleDissect);
      } else {
         searchParams.set("saleDissect", saleDissect);
      }
      navigate({
         pathname: location.pathname,
         search: searchParams.toString(),
      });

      if (saleDissect == 0) {
         setValueAnalytis("the_last_3_days");
         const daySDefault = new Date(
            dayStart.getFullYear(),
            dayStart.getMonth(),
            dayStart.getDate() - 2,
            0,
            0,
            0
         );
         const dayEDefault = new Date(
            dayStart.getFullYear(),
            dayStart.getMonth(),
            dayStart.getDate(),
            23,
            59,
            59
         );
         setDayRevenueStart(daySDefault);
         setDayRevenueEnd(dayEDefault);
      } else if (saleDissect == 1) {
         setValueAnalytis("the_last_7_days");
         const daySDefault = new Date(
            dayStart.getFullYear(),
            dayStart.getMonth(),
            dayStart.getDate() - 6,
            0,
            0,
            0
         );
         const dayEDefault = new Date(
            dayStart.getFullYear(),
            dayStart.getMonth(),
            dayStart.getDate(),
            23,
            59,
            59
         );
         setDayRevenueStart(daySDefault);
         setDayRevenueEnd(dayEDefault);
      } else if (saleDissect == 2) {
         setValueAnalytis(t("this_month"));
         const daySDefault = new Date(
            dayStart.getFullYear(),
            dayStart.getMonth(),
            1,
            0,
            0,
            0
         );
         const dayEDefault = new Date(
            dayStart.getFullYear(),
            dayStart.getMonth(),
            getLastDayOfMonth(dayStart.getFullYear(), dayStart.getMonth()),
            23,
            59,
            59
         );
         setDayRevenueStart(daySDefault);
         setDayRevenueEnd(dayEDefault);
      } else if (saleDissect == 3) {
         setValueAnalytis("last_month");
         const daySDefault = new Date(
            dayStart.getFullYear(),
            dayStart.getMonth() - 1,
            1,
            0,
            0,
            0
         );
         const dayEDefault = new Date(
            dayStart.getFullYear(),
            dayStart.getMonth() - 1,
            getLastDayOfMonth(dayStart.getFullYear(), dayStart.getMonth() - 1),
            23,
            59,
            59
         );
         setDayRevenueStart(daySDefault);
         setDayRevenueEnd(dayEDefault);
      }
   }, [saleDissect]);

   // type date report best selling product
   useEffect(() => {
      if (!searchParams.has("bestSellingDate")) {
         searchParams.append("bestSellingDate", bestSellingDate);
      } else {
         searchParams.set("bestSellingDate", bestSellingDate);
      }
      navigate({
         pathname: location.pathname,
         search: searchParams.toString(),
      });

      if (bestSellingDate == 0) {
         setValueBestSelling("the_last_3_days");
         const daySDefault = new Date(
            dayStart.getFullYear(),
            dayStart.getMonth(),
            dayStart.getDate() - 2,
            0,
            0,
            0
         );
         const dayEDefault = new Date(
            dayStart.getFullYear(),
            dayStart.getMonth(),
            dayStart.getDate(),
            23,
            59,
            59
         );
         setPartDayStart(daySDefault);
         setPartDayEnd(dayEDefault);
      } else if (bestSellingDate == 1) {
         setValueBestSelling("the_last_7_days");
         const daySDefault = new Date(
            dayStart.getFullYear(),
            dayStart.getMonth(),
            dayStart.getDate() - 6,
            0,
            0,
            0
         );
         const dayEDefault = new Date(
            dayStart.getFullYear(),
            dayStart.getMonth(),
            dayStart.getDate(),
            23,
            59,
            59
         );
         setPartDayStart(daySDefault);
         setPartDayEnd(dayEDefault);
      } else if (bestSellingDate == 2) {
         setValueBestSelling("this_month");
         const daySDefault = new Date(
            dayStart.getFullYear(),
            dayStart.getMonth(),
            1,
            0,
            0,
            0
         );
         const dayEDefault = new Date(
            dayStart.getFullYear(),
            dayStart.getMonth(),
            getLastDayOfMonth(dayStart.getFullYear(), dayStart.getMonth()),
            23,
            59,
            59
         );
         setPartDayStart(daySDefault);
         setPartDayEnd(dayEDefault);
      } else if (bestSellingDate == 3) {
         setValueBestSelling("last_month");
         const daySDefault = new Date(
            dayStart.getFullYear(),
            dayStart.getMonth() - 1,
            1,
            0,
            0,
            0
         );
         const dayEDefault = new Date(
            dayStart.getFullYear(),
            dayStart.getMonth() - 1,
            getLastDayOfMonth(dayStart.getFullYear(), dayStart.getMonth() - 1),
            23,
            59,
            59
         );
         setPartDayStart(daySDefault);
         setPartDayEnd(dayEDefault);
      }
   }, [bestSellingDate]);

   // type report best selling
   useEffect(() => {
      if (!searchParams.has("bestSellingType")) {
         searchParams.append("bestSellingType", bestSellingType);
      } else {
         searchParams.set("bestSellingType", bestSellingType);
      }
      navigate({
         pathname: location.pathname,
         search: searchParams.toString(),
      });

      if (bestSellingType == 0) {
         setValueTypeBestSelling("by_revenue");
         setCacheKeySelling("listBestSellingProduct");
         setTitleBestSale(t("revenue_title"));
      } else if (bestSellingType == 1) {
         setValueTypeBestSelling(t("by_quantity"));
         setCacheKeySelling("listBestSellingProductQty");
         setTitleBestSale(t("quantity_title"));
      }
   }, [bestSellingType]);

   const renderRefreshTooltip = (props) => (
      <Tooltip id="refresh-tooltip" {...props}>
         Refresh
      </Tooltip>
   );
   // const renderCollapseTooltip = (props) => (
   //   <Tooltip id="refresh-tooltip" {...props}>
   //     Collapse
   //   </Tooltip>
   // );

   const dateOption = [];

   let focusDate = new Date(dayRevenueStart);
   while (focusDate <= dayRevenueEnd) {
      dateOption.push(focusDate.getDate());
      focusDate.setDate(focusDate.getDate() + 1);
   }

   const { data: revenueToday, loading: loadingRevenueToday } = useQuery({
      queryKey: ["revenueToday", typeSaleResult, dayStart, dayEnd],
      queryFn: () => SaleDashboardService.reportRevenue(dayStart, dayEnd),
      refetchOnWindowFocus: false
   }
   );

   const { data: orderReturnToday, loading: loadingOrderReturnToday } = useQuery({
      queryKey: ["orderReturnToday", typeSaleResult, dayStart, dayEnd],
      queryFn: () => SaleDashboardService.reportRevenue(dayStart, dayEnd),
      refetchOnWindowFocus: false
   }
   );

   const { data: orderToday, loading: loadingOrderToday } = useQuery({
      queryKey: ["countOrderToday", typeSaleResult, dayStart, dayEnd],
      queryFn: () => SaleDashboardService.countOrder(dayStart, dayEnd),
      refetchOnWindowFocus: false
   }
   );


   const { data: bestSellingProducts, loading: loadingBestSelling } = useQuery({
      queryKey: [cacheKeySelling, bestSellingType, partDayStart, partDayEnd],
      queryFn: () => {
         if (bestSellingType == 0) {
            return SaleDashboardService.getListBestSellingProduct(
               partDayStart,
               partDayEnd
            );
         } else if (bestSellingType == 1) {
            return SaleDashboardService.getListBestSellingProductByQty(
               partDayStart,
               partDayEnd
            );
         }
      },
      refetchOnWindowFocus: false
   }
   );

   const topProducts = bestSellingProducts?.splice(0, 10);

   const [topProductsDataChart, setTopProductsDataChart] = useState([]);
   useEffect(() => {
      let data = [];
      if (cacheKeySelling == "listBestSellingProduct") {
         data = topProducts?.map((product) => {
            return { x: product.name, y: product.total };
         });
      }
      if (cacheKeySelling == "listBestSellingProductQty") {
         data = topProducts?.map((product) => {
            return { x: product.name, y: product.product_uom_qty };
         });
      }
      setTopProductsDataChart(data);
   }, [bestSellingProducts]);

   const { data: percent } = useQuery({
      queryKey: ["percentRevenue", dayStart, dayEnd],
      queryFn: () => SaleDashboardService.reportRevenuePercentPart(dayStart, dayEnd),
      refetchOnWindowFocus: false
   }
   );

   const { data: revenueList, loading: loadingRevenueList } = useQuery({
      queryKey: ["revenueChart", dayRevenueStart, dayRevenueEnd],
      queryFn: () =>
         SaleDashboardService.reportRevenueByDate(
            dayRevenueStart,
            dayRevenueEnd
         ),
      refetchOnWindowFocus: false
   }
   );

   const [dataChart, setDataChart] = useState([]);
   useEffect(() => {
      setDataChart(
         dateOption.map((item) => {
            let value = 0;
            revenueList?.forEach((dateRevenue) => {
               if (item == dateRevenue.date.getDate()) {
                  value = dateRevenue.price_subtotal;
               }
            });
            return value;
         })
      );
   }, [revenueList]);

   const options = {
      series: [
         {
            name: t("analysis_sale"),
            data: dataChart,
         },
      ],
      chart: {
         height: 273,
         type: "area",
         zoom: {
            enabled: false,
         },
      },
      colors: ["#FF505B"],
      dataLabels: {
         enabled: false,
      },
      stroke: {
         curve: "straight",
      },
      title: {
         text: "",
         align: "left",
      },
      xaxis: {
         categories: dateOption,
      },
      yaxis: {
         min: 0,
         max: Math.max(...dataChart),
         labels: {
            formatter: (val) => {
               if (val > 1000000000) {
                  return (val / 1000000000).toFixed(1) + "B";
               }
               if (val > 1000000) {
                  return (val / 1000000).toFixed(1) + "M";
               }
               if (val > 1000) {
                  return (val / 1000).toFixed(1) + "K";
               }
               return val;
            },
         },
      },
      legend: {
         position: "top",
         horizontalAlign: "left",
      },
   };
   const optionsBestSale = {
      series: [
         {
            name: titleBestSale,
            data: topProductsDataChart,
         },
      ],
      chart: {
         height: 273,
         type: "bar",
         zoom: {
            enabled: false,
         },
      },
      plotOptions: {
         bar: {
            borderRadius: 4,
            borderRadiusApplication: "end",
            horizontal: true,
         },
      },
      colors: ["#FF505B"],
      dataLabels: {
         style: {
            colors: ["rgb(9, 44, 76)"],
         },
         formatter: (val) => {
            if (val > 1000000000) {
               return (val / 1000000000).toFixed(1) + "B";
            }
            if (val > 1000000) {
               return (val / 1000000).toFixed(1) + "M";
            }
            if (val > 1000) {
               return (val / 1000).toFixed(1) + "K";
            }
            return val;
         },
         enabled: true,
      },
      stroke: {
         curve: "straight",
      },
      title: {
         text: "",
         align: "left",
      },
      tooltip: {
         y: {
            formatter: (value) => {
               if (bestSellingType == 0) {
                  return formatPrice(value, "VND");
               }

               if (bestSellingType == 1) {
                  return formatQuantity(value);
               }
            },
         },
      },
      xaxis: {
         labels: {
            formatter: (val) => {
               if (val > 1000000000) {
                  return (val / 1000000000).toFixed(1) + "B";
               }
               if (val > 1000000) {
                  return (val / 1000000).toFixed(1) + "M"; // Định dạng triệu (M)
               }
               if (val > 1000) {
                  return (val / 1000).toFixed(1) + "K"; // Định dạng ngàn (K)
               }
               return val; // Hiển thị giá trị gốc nếu nhỏ hơn 1000
            },
         },
      },
      yaxis: {
         categories: topProductsDataChart?.map((product) => product.x), // Danh sách các nhãn từ dữ liệu
      },

      legend: {
         position: "top",
         horizontalAlign: "left",
      },
   };

   return (
      <>
         <div className="page-wrapper fade-in">
            <div className="content">
               <div className="row col-12 sales-today">
                  <div className="card-header d-flex justify-content-between align-items-center">
                     <h4 className="sales-today-title mb-3 mt-2 text-uppercase">
                        {t(titleSaleResult)}
                     </h4>

                     <div className="graph-sets">
                        <div className="dropdown dropdown-wraper">
                           <button
                              className="btn btn-white btn-sm dropdown-toggle d-flex align-items-center"
                              type="button"
                              id="dropdown-sales"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                           >
                              <Calendar className="feather-14" />
                              {t(valueSaleResult)}
                           </button>
                           <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdown-sales"
                           >
                              <li>
                                 <Link
                                    to="#"
                                    className="dropdown-item"
                                    onClick={() => setTypeSaleResult(0)}
                                 >
                                    {t("value_report_today")}
                                 </Link>
                              </li>
                              <li>
                                 <Link
                                    to="#"
                                    className="dropdown-item"
                                    onClick={() => setTypeSaleResult(1)}
                                 >
                                    {t("value_report_lastday")}
                                 </Link>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div className="row col-12 sales-cards d-lg-flex align-items-center">
                     <div className="col-xl-3 col-sm-6 col-12">
                        <div className="card color-info bg-primary mb-4 card-today">
                           <ImageWithBasePath
                              src="assets/img/icons/total-sales.svg"
                              alt="img"
                           />
                           <h3>
                              <CountUp
                                 end={
                                    revenueToday
                                       ? revenueToday[0]?.price_subtotal
                                       : 0
                                 }
                                 duration={4}
                                 loading={loadingRevenueToday}
                              >
                                 +
                              </CountUp>
                           </h3>
                           <p>{t("revenue")}</p>
                           <OverlayTrigger
                              placement="top"
                              overlay={renderRefreshTooltip}
                           >
                              <Link
                                 data-bs-toggle="tooltip"
                                 className="feather-dashboard"
                              >
                                 <RotateCcw className="feather-16" />
                              </Link>
                           </OverlayTrigger>
                        </div>
                     </div>
                     <div className="col-xl-2 col-sm-6 col-12">
                        <div className="card color-info bg-info mb-4 card-today">
                           <ImageWithBasePath
                              src="assets/img/icons/order.svg"
                              alt="img"
                           />
                           <h3>
                              <CountUp
                                 end={orderToday ? orderToday : 0}
                                 loading={loadingOrderToday}
                                 duration={4}
                              >
                                 +
                              </CountUp>
                           </h3>
                           <p>{t("order_today")}</p>
                           <OverlayTrigger
                              placement="top"
                              overlay={renderRefreshTooltip}
                           >
                              <Link
                                 data-bs-toggle="tooltip"
                                 data-bs-placement="top"
                                 className="feather-dashboard"
                              >
                                 <RotateCcw className="feather-16" />
                              </Link>
                           </OverlayTrigger>
                        </div>
                     </div>
                     <div className="col-xl-2 col-sm-6 col-12">
                        <div className="card color-info bg-secondary mb-4 card-today">
                           <ImageWithBasePath
                              src="assets/img/icons/purchased-earnings.svg"
                              alt="img"
                           />
                           <h3>
                              <CountUp
                                 end={orderReturnToday ? orderReturnToday : 0}
                                 loading={loadingOrderReturnToday}
                                 duration={4}
                              >
                                 +
                              </CountUp>
                           </h3>
                           <p>{t("return_order")}</p>
                           <OverlayTrigger
                              placement="top"
                              overlay={renderRefreshTooltip}
                           >
                              <Link
                                 data-bs-toggle="tooltip"
                                 data-bs-placement="top"
                                 className="feather-dashboard"
                              >
                                 <RotateCcw className="feather-16" />
                              </Link>
                           </OverlayTrigger>
                        </div>
                     </div>
                     <div className="col-xl-5 col-sm-6 col-12">
                        <div className="card d-flex align-items-center justify-content-between default-cover mb-4 card-today">
                           <div>
                              <h6 className="earning">{t("earning")}</h6>
                              <h3>
                                 <CountUp
                                    end={percent?.revenue ? percent?.revenue : 0}
                                    duration={4}
                                 >
                                    +
                                 </CountUp>
                              </h3>
                              <p className="sales-range">
                                 {percent?.percent >= 0 ? (
                                    <span className="text-success d-flex align-items-center">
                                       <ChevronUp className="feather-16" />
                                       {percent?.percent}%&nbsp;
                                    </span>
                                 ) : (
                                    <span className="text-danger">
                                       <ChevronDown className="feather-16" />
                                       {percent?.percent}%&nbsp;
                                    </span>
                                 )}
                                 <p>
                                    {percent?.percent >= 0
                                       ? t("increased")
                                       : t("reduce")}{" "}
                                    {t("compare_percent")}
                                 </p>
                              </p>
                           </div>
                           <ImageWithBasePath
                              src="assets/img/icons/weekly-earning.svg"
                              alt="img"
                           />
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-sm-12 col-md-12 col-xl-12 d-flex">
                     <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                        <div className="card flex-fill default-cover">
                           <div className="card-header d-flex justify-content-between align-items-center">
                              <h5 className="card-title mb-0">
                                 {t("revenue_analytis")}
                              </h5>
                              <div className="graph-sets">
                                 <div className="dropdown dropdown-wraper">
                                    <button
                                       className="btn btn-white btn-sm dropdown-toggle d-flex align-items-center"
                                       type="button"
                                       id="dropdown-sales"
                                       data-bs-toggle="dropdown"
                                       aria-expanded="false"
                                    >
                                       <Calendar className="feather-14" />
                                       {t(valueAnalytis)}
                                    </button>
                                    <ul
                                       className="dropdown-menu"
                                       aria-labelledby="dropdown-sales"
                                    >
                                       <li>
                                          <Link
                                             to="#"
                                             className="dropdown-item"
                                             onClick={() => setSaleDissect(0)}
                                          >
                                             {t("the_last_3_days")}
                                          </Link>
                                       </li>
                                       <li>
                                          <Link
                                             to="#"
                                             className="dropdown-item"
                                             onClick={() => setSaleDissect(1)}
                                          >
                                             {t("the_last_7_days")}
                                          </Link>
                                       </li>
                                       <li>
                                          <Link
                                             to="#"
                                             className="dropdown-item"
                                             onClick={() => setSaleDissect(2)}
                                          >
                                             {t("this_month")}
                                          </Link>
                                       </li>
                                       <li>
                                          <Link
                                             to="#"
                                             className="dropdown-item"
                                             onClick={() => setSaleDissect(3)}
                                          >
                                             {t("last_month")}
                                          </Link>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                           <div className="card-body">
                              {!loadingRevenueList ? (
                                 <div>
                                    <div
                                       id="sales-analysis"
                                       className="chart-set"
                                    />
                                    <Chart
                                       options={options}
                                       series={options.series}
                                       type="area"
                                       height={430}
                                    />
                                 </div>
                              ) : (
                                 <Loading />
                              )}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-sm-12 col-md-12 col-xl-12 d-flex">
                     <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                        <div className="card flex-fill default-cover">
                           <div className="card-header d-flex justify-content-between align-items-center">
                              <h5 className="card-title mb-0">
                                 {t("best_product_analytis")}
                              </h5>
                              <div className="graph-sets d-flex">
                                 <div className="dropdown dropdown-wraper me-2">
                                    <button
                                       className="btn btn-white btn-sm dropdown-toggle d-flex align-items-center"
                                       type="button"
                                       id="dropdown-sales"
                                       data-bs-toggle="dropdown"
                                       aria-expanded="false"
                                    >
                                       <Calendar className="feather-14" />
                                       {t(valueBestSelling)}
                                    </button>
                                    <ul
                                       className="dropdown-menu"
                                       aria-labelledby="dropdown-sales"
                                    >
                                       <li>
                                          <Link
                                             to="#"
                                             className="dropdown-item"
                                             onClick={() =>
                                                setbestSellingDate(0)
                                             }
                                          >
                                             {t("the_last_3_days")}
                                          </Link>
                                       </li>
                                       <li>
                                          <Link
                                             to="#"
                                             className="dropdown-item"
                                             onClick={() =>
                                                setbestSellingDate(1)
                                             }
                                          >
                                             {t("the_last_7_days")}
                                          </Link>
                                       </li>
                                       <li>
                                          <Link
                                             to="#"
                                             className="dropdown-item"
                                             onClick={() =>
                                                setbestSellingDate(2)
                                             }
                                          >
                                             {t("this_month")}
                                          </Link>
                                       </li>
                                       <li>
                                          <Link
                                             to="#"
                                             className="dropdown-item"
                                             onClick={() =>
                                                setbestSellingDate(3)
                                             }
                                          >
                                             {t("last_month")}
                                          </Link>
                                       </li>
                                    </ul>
                                 </div>
                                 <div className="dropdown dropdown-wraper">
                                    <button
                                       className="btn btn-white btn-sm dropdown-toggle d-flex align-items-center"
                                       type="button"
                                       id="dropdown-sales"
                                       data-bs-toggle="dropdown"
                                       aria-expanded="false"
                                    >
                                       <Calendar className="feather-14" />
                                       {t(valueTypeBestSelling)}
                                    </button>
                                    <ul
                                       className="dropdown-menu"
                                       aria-labelledby="dropdown-sales"
                                    >
                                       <li>
                                          <Link
                                             to="#"
                                             className="dropdown-item"
                                             onClick={() =>
                                                setbestSellingType(0)
                                             }
                                          >
                                             {t("by_revenue")}
                                          </Link>
                                       </li>
                                       <li>
                                          <Link
                                             to="#"
                                             className="dropdown-item"
                                             onClick={() =>
                                                setbestSellingType(1)
                                             }
                                          >
                                             {t("by_quantity")}
                                          </Link>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                           <div className="card-body">
                              {!loadingBestSelling ? (
                                 <div>
                                    <div
                                       id="sales-analysis"
                                       className="chart-set"
                                    />
                                    <Chart
                                       options={optionsBestSale}
                                       series={optionsBestSale.series}
                                       type="bar"
                                       height={430}
                                    />
                                 </div>
                              ) : (
                                 <Loading />
                              )}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default SalesDashboard;
