import { create } from 'zustand'

const useTableStore = create((set) => ({
  fields: {},
  selectedRowKeys: [],
  domainTable: [],
  page: 0,
  specification: {},
  setFields: (newFields) => set((state) => ({
    fields: {
      ...state.fields,
      ...newFields,
    },
  })),
  setSelectedRowKeys: (newFields) => set(() => ({ selectedRowKeys: newFields })),
  setDomainTable: (newDomainTable) => set(() => ({ domainTable: newDomainTable })),
  setSpecification: (newSpecification) => set(() => ({ specification: newSpecification })),
  setPage: (newPage) => set(() => ({ page: newPage })),
}))

export default useTableStore
