import initialState from './InitialValue';

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case "Dashbaord_RecentProduct":
      return { ...state, dashboard_recentproduct: action.payload };
    case "Dashbaord_ExpiredProduct":
      return { ...state, dashboard_expiredproduct: action.payload };
    case "Salesdashbaord_ExpiredProduct":
      return { ...state, saleshdashboard_recenttransaction: action.payload };
    case "toggle_header":
      return { ...state, toggle_header: action.payload };
    case "Invoicereport_Data":
      return { ...state, invoicereport_data: action.payload };
    case "Rolesandpermission_data":
      return { ...state, rolesandpermission_data: action.payload };
    case "Deleteaccount_data":
      return { ...state, deleteaccount_data: action.payload };
    case "Layoutstyle_data":
      return { ...state, layoutstyledata: action.payload };
    case "Settings_data":
      return { ...state, settings_data: action.payload };
    case "Menu_Focus":
      return { ...state, menu_focus: action.payload };
    case "Context_company_data":
      return { ...state, context_company: action.payload };
    case "setFields":
      return { ...state, fields: action.payload };
    default:
      return state
  }
}

export default rootReducer
