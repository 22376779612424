/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { User } from "react-feather";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next"; // For translation
import Loader from "react-js-loader";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import useLogoutSubmit from "../../hooks/useLogoutSubmit";
import useUserSubmit from "../../hooks/useUserSubmit";
import { all_routes } from "../../Router/all_routes";
import { useMetaContext } from "../../core/utils/context/MetaContext";
import ImageWithBasePath from "../general/ImageCustom";
import SwitchLanguage from "./SwitchLanguage";
import { useSelector, useDispatch } from "react-redux";
import { set_context_company_data } from "../../core/utils/redux/action";
import StoreService from "../../core/services/company/StoreService";
import useAsync from "../../hooks/useAsync";
import SelectStore from "./SelectStore";
import useHeaderStore from "../../store/header";
const Header = () => {
  let pathname = location.pathname;

  const dispatch = useDispatch();
  const context = useSelector((state) => state.context_company);
  const { setCompanies } = useHeaderStore()
  const { t } = useTranslation(); // Translation
  const { metadata, loadingImport } = useMetaContext();
  let navigate = useNavigate();
  const { onLogout } = useLogoutSubmit(navigate);
  const { getValues, imageSrc, idUser } = useUserSubmit();

  const { handleSubmit } = useForm();
  const route = all_routes;
  const [toggle, SetToggle] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const isElementVisible = (element) => {
    return element?.offsetWidth > 0 || element?.offsetHeight > 0;
  };

  useEffect(() => {
    const handleMouseover = (e) => {
      e.stopPropagation();

      const body = document.body;
      const toggleBtn = document.getElementById("toggle_btn");

      if (
        body.classList.contains("mini-sidebar") &&
        isElementVisible(toggleBtn)
      ) {
        e.preventDefault();
      }
    };

    document.addEventListener("mouseover", handleMouseover);

    return () => {
      document.removeEventListener("mouseover", handleMouseover);
    };
  }, []);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      );
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("msfullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "msfullscreenchange",
        handleFullscreenChange
      );
    };
  }, []);
  const handleSidebar = (e) => {
    e.preventDefault();
    document.body.classList.toggle("mini-sidebar");
    SetToggle((current) => !current);
  };
  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };
  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };
  const sidebarOverlay = () => {
    document?.querySelector(".main-wrapper")?.classList?.toggle("slide-nav");
    document?.querySelector(".sidebar-overlay")?.classList?.toggle("opened");
    document?.querySelector("html")?.classList?.toggle("menu-opened");
  };

  const exclusionArray = [
    "/reactjs/template/dream-pos/index-three",
    "/reactjs/template/dream-pos/index-one",
  ];
  if (exclusionArray.indexOf(window.location.pathname) >= 0) {
    return "";
  }

  const toggleFullscreen = (elem) => {
    elem = elem || document.documentElement;
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  };

  // STORE SERVICE
  const [stores, setStores] = useState([]);

  const [currentStore, setCurrentStore] = useState({});

  const [selectedStoreList, setSelectedStoreList] = useState([]);

  const { data } = useAsync(
    () => StoreService.getAllowedStore(getValues("sub")),
    "allowed-store-list"
  );


  const handleSwitchCompany = async (store) => {
    setSelectedStoreList((selectedStoreList) => {
      const storeExists = selectedStoreList.some(
        (selectedStore) => selectedStore.id === store.id
      );
      if (storeExists) {
        // Remove the store if it exists
        return selectedStoreList.filter(
          (selectedStore) => selectedStore.id !== store.id
        );
      } else {
        // Add the store if it doesn't exist
        return [...selectedStoreList, store];
      }
    });
  };

  const handleSelectAllStore = async () => {
    const allStores = data?.records || [];
    const storedCompanyIds = context || [];
    const newCompanyIds = allStores.map((item) => item.id);
    // Check if all new company ids are already stored
    const AllInContext = newCompanyIds.every((id) =>
      storedCompanyIds.includes(id)
    );

    if (AllInContext) {
      dispatch(
        set_context_company_data(selectedStoreList.map((item) => item.id))
      );
    } else {
      dispatch(set_context_company_data(newCompanyIds));
    }
  };

  const fetchCurrentCompany = async () => {
    await StoreService.getUserCompanyByUserId().then((res) => {
      setCurrentStore(res.records[0]?.company_id);
      setStores(res.records[0]?.company_ids);
      const checkbox = document.getElementById(
        `checkbox-${res.records[0]?.company_id.id}`
      );
      if (checkbox) {
        checkbox.checked = true;
      }
      if (selectedStoreList.length === 0) {
        setSelectedStoreList([res.records[0]?.company_id]);
        setCompanies([res.records[0]?.company_id.id])
      }
      if (selectedStoreList) {
        setCompanies(selectedStoreList.map((item) => item.id))
      }
    });
  };

  useEffect(() => {
    fetchCurrentCompany();
  }, [data, selectedStoreList]);

  return (
    <>
      <div className="header fixed w-full">
        <div className={`header-left flex !justify-between !px-4 ${toggle ? "" : "active"}`}>
          <Link
            onClick={(e) => {
              e.preventDefault()
              navigate("/sales-dashboard");
            }}
            className="logo logo-normal"
          >
            {metadata &&
              (!loadingImport ? (
                <div className="flex items-center justify-center gap-[16px]">
                  <div className="flex items-start">
                    <img
                      src={metadata?.logo}
                      alt="logo"
                      width={140}
                      height={60}
                      className="h-auto max-h-[60px] object-contain object-top"
                    />
                  </div>
                </div>
              ) : (
                <ImageWithBasePath
                  src="../../../public/assets/logo/vitrust-white.svg"
                  alt="img"
                />
              ))}
          </Link>
          <Link
            onClick={() => navigate("/sales-dashboard")}
            className="logo logo-white"
          >
            <ImageWithBasePath src="assets/logo/vitrust-three.svg" alt="img" />
          </Link>
          <Link
            onClick={() => navigate("/sales-dashboard")}
            className="logo-small"
          >
            <ImageWithBasePath src={metadata?.logo || ''} alt="img" />
          </Link>
          {/* <Link
            id="toggle_btn"
            to="#"
            style={{
              display:
                pathname.includes("tasks") || pathname.includes("pos1")
                  ? "none"
                  : pathname.includes("compose")
                    ? "none"
                    : "",
            }}
            onClick={handleSidebar}
            className="flex !size-8 cursor-pointer items-center justify-center shadow hover:shadow-2xl"
          >
            <img
              src={"assets/img/arrow.svg"}
              alt="arrow"
              className={`size-6 ${toggle ? "rotate-180" : ""} transform transition-all`}
            />
          </Link> */}
        </div>
        {/* Header Menu */}
        {/* <div className="ml-[292px]">
          <ImageWithBasePath
            src="assets/img/header/banner-header.svg"
            alt="banner-header"
            height={56}
            className="border border-[#021301] rounded-lg"
          />
        </div> */}
        <ul className="nav user-menu bg-white border-b border-[#E8E8E8] px-6 !justify-between">
          {/* Search */}
          {/* <li className='nav-item nav-searchinputs'>
            <div className='top-nav-search'>
              <Link to='#' className='responsive-search'>
                <Search />
              </Link>

              <SearchBar />
            </div>
          </li> */}
          {/* <li className='nav-item nav-item-box'>
            <Link
              to='#'
              id='btnFullscreen'
              onClick={() => toggleFullscreen()}
              className={isFullscreen ? 'Exit Fullscreen' : 'Go Fullscreen'}
            >
              <FeatherIcon icon='maximize' />
            </Link>
          </li> */}

          <li className="nav-item dropdown has-arrow main-drop select-store-dropdown">
            <SelectStore />
          </li>

          <li className="nav-item nav-item-box">
            <ImageWithBasePath
              src="assets/img/header/glass-search.svg"
              alt="glass-search"
              width={24}
              height={24}
            />
          </li>
          <li className="nav-item nav-item-box">
            <ImageWithBasePath
              src="assets/img/header/bell.svg"
              alt="bell"
              width={24}
              height={24}
            />
          </li>
          <li className="nav-item nav-item-box">
            <Link to="/pos-session">
              <FeatherIcon icon="shopping-cart" />
            </Link>
          </li>
          <li>
            <SwitchLanguage />
          </li>
          <li className="nav-item dropdown has-arrow main-drop">
            <Link
              to="#"
              className="dropdown-toggle nav-link userset"
              data-bs-toggle="dropdown"
            >
              <span className="user-info">
                <span className="user-letter">
                  {imageSrc ? (
                    <img
                      src={imageSrc}
                      alt="img"
                      className="img-fluid w-100 h-100"
                    />
                  ) : (
                    <Loader
                      type="ping-cube"
                      color="#ED1C24"
                      bgColor="#ED1C24"
                      size={30}
                    />
                  )}

                </span>
                {/* <span className='user-detail'>
                  <span className='user-name'>{getValues('name')}</span>
                  <span className='user-role'>{getValues('email')}</span>
                </span> */}
              </span>
            </Link>
            <div className="dropdown-menu menu-drop-user">
              <div className="profilename">
                <hr className="m-0" />
                <Link className="dropdown-item" to={route.profile}>
                  <User className="me-2" />
                  My profile
                  {/* {t("my_profile")} */}
                </Link>
                <hr className="m-0" />
                <form onSubmit={handleSubmit(onLogout)}>
                  <button
                    type="submit"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      display: "inline",
                      padding: "0px",
                      width: "100%",
                    }}
                  >
                    <div className="dropdown-item logout pb-0">
                      <ImageWithBasePath
                        src="assets/img/icons/log-out.svg"
                        alt="img"
                        className="me-2"
                      />{" "}
                      {/* {t("logout")} */}
                      Log out
                    </div>
                  </button>
                </form>
              </div>
            </div>
          </li>
        </ul>
        {/* /Header Menu */}
        {/* Mobile Menu */}
        <div className="dropdown mobile-user-menu">
          <Link
            to="#"
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v" />
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link className="dropdown-item" to="profile">
              {t("my_profile")}
            </Link>
            <Link className="dropdown-item" to="generalsettings">
              {t("settings")}
            </Link>
            <form onSubmit={handleSubmit(onLogout)}>
              <button
                type="submit"
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  display: "inline",
                  padding: "0px",
                  width: "100%",
                }}
              >
                <div className="dropdown-item logout pb-0">
                  <ImageWithBasePath
                    src="assets/img/icons/log-out.svg"
                    alt="img"
                    className="me-2"
                  />{" "}
                  {t("logout")}
                </div>
              </button>
            </form>
          </div>
        </div>
        {/* /Mobile Menu */}
      </div>
      {/* <HorizontalSidebar /> */}
    </>
  );
};

export default Header;
