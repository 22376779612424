import React, { useEffect } from 'react'
import ExcelService from '../../core/services/import-excel'
import { useMetaContext } from '../../core/utils/context/MetaContext'
import useExcelStore from '../../store/excel'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const UploadButton = () => {
  const { setLoadingImport, selectedFile, setSelectedFile, setErrorData, loadingImport } = useMetaContext()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const model = queryParams.get('model');
  const { dataParse, setDataParse } = useExcelStore()
  const { idFile, setIdFile, setIsFileLoaded, isFileLoaded } = useExcelStore()
  const { t } = useTranslation()

  const handleDataParseChange = (newData) => {
    setDataParse(newData);
    setIsFileLoaded(true)
  };

  const handleIdFileChange = (newId) => {
    if (idFile) {
      setIdFile(idFile)
    } else if (newId != null) {
      setIdFile(newId)
    } else {
      setIdFile(null)
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      const isExcelFile = (file) => {
        const acceptedTypes = [
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
          'application/vnd.ms-excel', // .xls
          'application/vnd.oasis.opendocument.spreadsheet', // .ods
          'application/vnd.apple.numbers', // .numbers (for Mac)
          'text/csv', // .csv (often considered as spreadsheet)
        ]
        return acceptedTypes.includes(file.type) || /\.(xlsx|xls|csv|ods|numbers)$/i.test(file.name)
      }
      if (isExcelFile(file)) {
        setErrorData()
        setSelectedFile(file)
        setLoadingImport(true)
      } else {
        alert('Bạn chỉ có thể tải lên file XLSX!')
        event.target.value = null // Reset file input
      }
    }
  }
  const FetchDataUpload = async (modal, files, id) => {
    if (id != null) {
      await ExcelService.uploadIdFile({ modal, files, id }).then((responsive) => {
        handleIdFileChange(responsive)
      })
    } else {
      await ExcelService.uploadFile({ modal, files }).then((responsive) => {
        handleIdFileChange(responsive)
      })
    }
  }
  const FetchDataPreview = async (id) => {
    await ExcelService.ParsePreview({ id, selectedSheet: dataParse?.options?.sheet, isHeader: true }).then((responsive) => {
      handleDataParseChange(responsive)
    })
  }
  useEffect(() => {
    if (model && selectedFile) {
      const formData = new FormData()
      formData.append('ufile', selectedFile, selectedFile.name)
      FetchDataUpload(model, formData, idFile)
    }
  }, [model, selectedFile])
  useEffect(() => {
    if (idFile) {
      if (isFileLoaded) return
      FetchDataPreview(idFile)
    }
  }, [idFile])

  return (
    <div>
      <input type='file' onChange={handleFileChange} style={{ display: 'none' }} id='fileInput' />
      <label
        style={{ boxShadow: "0px 1px 2px 0px rgba(55,93,251,0.08)" }}
        htmlFor='fileInput'
        className='bg-[#FF505B] rounded-[10px] p-[10px] text-[14px] font-medium text-[#FFF] cursor-pointer'
      >
        {!loadingImport ? t('upload-excel') : t('loading')}
      </label>
    </div>
  )
}

export default UploadButton
