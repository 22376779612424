import { MinusCircle, PlusCircle } from 'feather-icons-react/build/IconComponents'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import PropTypes from 'prop-types'
import POSService from '../../core/services/POSService'
import { useTranslation } from 'react-i18next'
import ImageWithBasePath from '../../components/general/ImageCustom'
import { formatPrice } from '../../core/utils/formatter'
const QuantityProduct = ({ product, order_detail, onQuantityChange, handleOrderDetailsUpdate, addingOrder }) => {
  const [quantity, setQuantity] = useState(product.qty)
  const { t } = useTranslation()

  const handleQuantityChange = async (newQuantity) => {
    if (newQuantity > 0 && !isNaN(newQuantity)) {
      let tax_rate = product.tax_ids_after_fiscal_position[0]?.amount / 100
      let taxAmount = product.price_unit * tax_rate
      let parse_quantity = parseInt(newQuantity, 10) // Parse new quantity to integer

      // Use the base unit price to calculate the total, not the subtotal
      let unitPrice = product.price_unit // Base unit price without tax
      let unitPriceIncl = product.price_unit + taxAmount // Base unit price with tax
      console.log('tax_rate', tax_rate)
      console.log('taxAmount', taxAmount)

      console.log('unitPriceIncl', unitPriceIncl)

      // Update product quantity in the backend
      await POSService.updateProductAmountInOrder(product.id, {
        order_id: order_detail.id, // Use current order ID
        product_id: product.product_id.id, // Use current product ID
        qty: parse_quantity, // Update the quantity
        price_subtotal_incl: unitPriceIncl * parse_quantity, // Recalculate subtotal with tax based on unit price
        price_subtotal: unitPrice * parse_quantity, // Recalculate subtotal without tax based on unit price
      })

      // Update local state and notify parent or other components
      setQuantity(newQuantity) // Update quantity in local state
      onQuantityChange(product.id, newQuantity) // Call the callback to notify change
      addingOrder(product.product_id.id)
      handleOrderDetailsUpdate(order_detail.id)
    }
  }
  const handleDecrement = () => handleQuantityChange(quantity - 1)
  const handleIncrement = () => handleQuantityChange(quantity + 1)
  const handleChange = (e) => handleQuantityChange(parseInt(e.target.value, 10))

  return (
    <>
      <div className='qty-item flex items-center justify-center text-center'>
        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-minus'>Minus</Tooltip>}>
          {order_detail.state === 'draft' ? (
            <Link
              to='#'
              className='dec flex items-center justify-center'
              onClick={(e) => {
                handleDecrement()
                e.preventDefault()
              }}
            >
              <MinusCircle className='h-3.5 w-3.5' />
            </Link>
          ) : (
            <></>
          )}
        </OverlayTrigger>
        <input
          type='text'
          className='form-control text-center'
          name='qty'
          value={quantity}
          onChange={handleChange}
          maxLength='8'
        />
        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-plus'>Plus</Tooltip>}>
          {order_detail.state === 'draft' ? (
            <Link
              to='#'
              className='inc flex items-center justify-center'
              onClick={(e) => {
                handleIncrement()
                e.preventDefault()
              }}
            >
              <PlusCircle className='h-3.5 w-3.5' />
            </Link>
          ) : (
            <></>
          )}
        </OverlayTrigger>
      </div>

      <div className='modal fade pos-modal' id={`item-${product.id}`} tabIndex={-1} aria-hidden='true'>
        <div className='modal-dialog modal-md modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header p-4'>
              <h5 className='modal-title'>{product.full_product_name}</h5>
              <button type='button' className='close' data-bs-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true'>×</span>
              </button>
            </div>

            <div className='modal-body p-4'>
              <div className='d-flex justify-center'>
                <ImageWithBasePath
                  src={product?.product_id?.image_128 || 'https://zippo.vn/wp-content/uploads/2022/02/no-image.jpeg'}
                  alt='Products'
                />
              </div>

              <div className='tabs-sets'>
                <div className='d-flex justify-center'>
                  {' '}
                  <div className='info'>
                    <span>SP {product.product_id.id}</span>
                    <h6>
                      <Link
                        to='#'
                        onClick={(e) => {
                          e.preventDefault()
                        }}
                      >
                        {product.full_product_name}
                      </Link>
                    </h6>
                    <p>{formatPrice(product.price_subtotal, product?.currency_id?.display_name || 'VND')}</p>
                  </div>
                </div>

                <div className='tab-content'>
                  <div className='order-body'>
                    <div className='default-cover mb-4 p-4' key={product?.id}>
                      <div className='row'>
                        <div className='qty-item flex items-center justify-center text-center'>
                          <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-minus'>Minus</Tooltip>}>
                            {order_detail.state === 'draft' ? (
                              <Link
                                to='#'
                                className='dec flex items-center justify-center'
                                onClick={(e) => {
                                  handleDecrement()
                                  e.preventDefault()
                                }}
                              >
                                <MinusCircle className='h-8 w-8' />
                              </Link>
                            ) : (
                              <></>
                            )}
                          </OverlayTrigger>
                          <input
                            type='text'
                            className='form-control text-center'
                            name='qty'
                            value={quantity}
                            onChange={handleChange}
                            maxLength='8'
                            style={{ width: '240px', height: '40px', fontSize: '24px' }}
                          />
                          <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-plus'>Plus</Tooltip>}>
                            {order_detail.state === 'draft' ? (
                              <Link
                                to='#'
                                className='inc flex items-center justify-center'
                                onClick={(e) => {
                                  handleIncrement()
                                  e.preventDefault()
                                }}
                              >
                                <PlusCircle className='h-8 w-8' />
                              </Link>
                            ) : (
                              <></>
                            )}
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

QuantityProduct.propTypes = {
  product: PropTypes.object.isRequired,
  order_detail: PropTypes.object.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  handleOrderDetailsUpdate: PropTypes.func.isRequired,
}

export default QuantityProduct
