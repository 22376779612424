import { create } from 'zustand'

const useSearchStore = create((set) => ({
  searchString: '',
  groupBy: [],
  filterBy: [],
  tableHead: [],
  hoveredIndexSearchList: null,
  setSearchString: (newSearch) => set(() => ({ searchString: newSearch })),
  setFilterBy: (newFilterBy) => set(() => ({ filterBy: newFilterBy })),
  setGroupBy: (newGroupBy) => set(() => ({ groupBy: newGroupBy })),
  setHoveredIndexSearchList: (newHoveredIndexSearchList) =>
    set(() => ({ hoveredIndexSearchList: newHoveredIndexSearchList })),
  setTableHead: (newTableHead) => set(() => ({ tableHead: newTableHead })),
  setSelectedTags: (newSelectedTags) => set(() => ({ selectedTags: newSelectedTags })),
}))

export default useSearchStore
