/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import alertify from 'alertifyjs'
import POSService from '../../core/services/POSService'
const RestaurantCreateTable = ({ floorId, setFloor, posId, updateTable }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  console.log(updateTable, 'updateTable')

  const [newTableForm, setNewTableForm] = useState({
    floor_id: 0,
    name: '',
  })

  const [editTableForm, setEditTableForm] = useState({
    table_id: 0,
    name: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setNewTableForm((prev) => ({ ...prev, [name]: value }))
  }

  const handleUpdate = (e) => {
    const { name, value } = e.target
    setEditTableForm((prev) => ({ ...prev, [name]: value }))
  }

  const handleCreateNewTable = async () => {
    alertify.success(t('create_table_success'))
    newTableForm.floor_id = floorId
    await POSService.createTableInFloor(newTableForm).then(async () => {
      await POSService.getListPOSById(posId).then((res) => {
        setFloor(res.records[0].floor_ids)
      })
    })
  }

  const handleUpdateNewTable = async () => {
    alertify.success(t('update_table_success'))
    editTableForm.table_id = updateTable.id
    await POSService.updateTable(editTableForm).then(async () => {
      await POSService.getListPOSById(posId).then((res) => {
        setFloor(res.records[0].floor_ids)
      })
    })
  }

  return (
    <>
      <div className='modal fade' id='create-new-table'>
        <div className='modal-dialog sales-details-modal'>
          <div className='modal-content'>
            <div className='page-wrapper-pos fade-in p-0'>
              <div className='content'>
                <div className='modal-header custom-modal-header border-0'>
                  <div className='page-title'>
                    <h4>{t('create_new_table')}</h4>
                  </div>

                  <button type='button' className='close' data-bs-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>×</span>
                  </button>
                </div>
                <div className='modal-body custom-modal-body'>
                  <div className='row'>
                    <div className='col-lg-12 pe-0'>
                      <div className='form-group mb3'>
                        <label className='mb-2'>{t('table_name')}</label>
                        <input
                          className='form-control form-control-lg group_formcontrol'
                          id='phone'
                          name='name'
                          type='text'
                          value={newTableForm.name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer-btn'>
                    <button type='button' className='btn btn-cancel me-2' data-bs-dismiss='modal'>
                      {t('cancel')}
                    </button>
                    <button
                      type='button'
                      className='btn btn-submit'
                      onClick={() => handleCreateNewTable()}
                      data-bs-dismiss='modal'
                    >
                      {t('save')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' id='update-table'>
        <div className='modal-dialog sales-details-modal'>
          <div className='modal-content'>
            <div className='page-wrapper-pos fade-in p-0'>
              <div className='content'>
                <div className='modal-header custom-modal-header border-0'>
                  <div className='page-title'>
                    <h4>{t('edit_new_table')}</h4>
                  </div>

                  <button type='button' className='close' data-bs-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>×</span>
                  </button>
                </div>
                <div className='modal-body custom-modal-body'>
                  <div className='row'>
                    <div className='col-lg-12 pe-0'>
                      <div className='form-group mb3'>
                        <label className='mb-2'>{t('table_name')}</label>
                        <input
                          className='form-control form-control-lg group_formcontrol'
                          name='name'
                          type='text'
                          placeholder={updateTable?.name}
                          value={editTableForm.name}
                          onChange={handleUpdate}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer-btn'>
                    <button type='button' className='btn btn-cancel me-2' data-bs-dismiss='modal'>
                      {t('cancel')}
                    </button>
                    <button
                      type='button'
                      className='btn btn-submit'
                      onClick={() => handleUpdateNewTable()}
                      data-bs-dismiss='modal'
                    >
                      {t('save')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RestaurantCreateTable
