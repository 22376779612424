// import qs from "qs";
/* eslint-disable no-unused-vars */
import requests from './httpService'
// import Cookies from "js-cookie";
import {
  callPath,
  updatePath,
  createPath,
  // delelePath,
  searchReadPath,
} from './endpoint'

const POSService = {
  getProducts: async (cate_id = 0) => {
    const domain = ['&', '&', ['active', '=', true], ['available_in_pos', '=', true], ['sale_ok', '=', true]]
    if (cate_id != 0) {
      domain.push('|', ['categ_id', '=', cate_id], ['categ_id.parent_id', '=', cate_id])
    }

    const jsonData = {
      model: 'product.product',
      method: 'web_search_read',
      kwargs: {
        domain: domain,
        specification: {
          image_128: {},
          image_256: {},
          product_variant_count: {},
          sale_ok: {},
          currency_id: {
            fields: {},
          },
          cost_currency_id: {
            fields: {},
          },
          priority: {},
          name: {},
          default_code: {},
          responsible_id: {
            fields: {
              display_name: {},
            },
          },
          product_tag_ids: {
            fields: {
              display_name: {},
            },
          },
          barcode: {},
          list_price: {},
          standard_price: {},
          categ_id: {
            fields: {
              display_name: {},
            },
          },
          taxes_id: {
            fields: {
              id: {},
              name: {},
              amount: {},
            },
          },
          detailed_type: {},
          type: {},
          show_on_hand_qty_status_button: {},
          qty_available: {},
          virtual_available: {},
          active: {},
          activity_exception_decoration: {},
          activity_exception_icon: {},
        },
      },
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  getCustomers: async (name = '') => {
    const jsonData = {
      model: 'res.partner',
      fields: ['id', 'name'],
      limit: 10,
      offset: 0,
      domain: [
        // ["customer_rank", ">", 0],
        ['name', 'like', name],
      ],
    }
    return requests.post(searchReadPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  getCategories: async () => {
    const jsonData = {
      model: 'product.category',
      fields: ['id', 'name', 'complete_name'],
      limit: 10,
      offset: 0,
      domain: [],
    }
    return requests.post(searchReadPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  getSessionIdByPosId: async (pos_id, context_company) => {
    const jsonData = {
      model: 'pos.session',
      method: 'web_search_read',
      with_context: { allowed_company_ids: context_company },
      kwargs: {
        domain: [
          ['config_id', '=', parseInt(pos_id)],
          ['state', '=', 'opened'],
        ],
        specification: {
          name: {},
          id: {},
        },
      },
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  getOrderBySession: async (sessionId) => {
    const jsonData = {
      model: 'pos.order',
      method: 'web_search_read',
      kwargs: {
        domain: [
          ['session_id', '=', parseInt(sessionId)],
          // ["state", "=", "draft"],
        ],
        specification: {
          currency_id: {
            fields: {},
          },
          name: {},
          session_id: {
            fields: {
              display_name: {},
            },
          },
          date_order: {},
          config_id: {
            fields: {
              display_name: {},
            },
          },
          pos_reference: {},
          tracking_number: {},
          partner_id: {
            fields: {
              display_name: {},
            },
          },
          employee_id: {
            fields: {
              display_name: {},
            },
          },
          amount_total: {},
          state: {},

          table_id: {
            fields: {
              id: {},
              name: {}
            }
          }
        },

        offset: 0,
        order: '',
        limit: 10,
        count_limit: 10001,
      },
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  getOrderDetail: async (id) => {
    const jsonData = {
      model: 'pos.order',
      method: 'web_read',
      ids: [id],
      kwargs: {
        specification: {
          state: {},
          has_refundable_lines: {},
          refunded_orders_count: {},
          failed_pickings: {},
          is_refunded: {},
          picking_count: {},
          sale_order_count: {},
          refund_orders_count: {},
          amount_tax: {},
          name: {},
          date_order: {},
          session_id: {
            fields: {
              display_name: {},
            },
          },
          employee_id: {
            fields: {
              display_name: {},
            },
          },
          user_id: {
            fields: {
              display_name: {},
            },
          },
          partner_id: {
            fields: {
              phone: {},
              display_name: {},
            },
            context: {
              res_partner_search_mode: 'customer',
            },
          },
          fiscal_position_id: {
            fields: {
              display_name: {},
            },
          },
          table_id: {
            fields: {
              display_name: {},
            },
          },
          customer_count: {},
          lines: {
            fields: {
              id: {},
              full_product_name: {},
              qty: {},
              customer_note: {},
              product_id: {
                fields: {
                  image_128: {},
                },
              },
              product_uom_id: {
                fields: {
                  display_name: {},
                },
              },
              price_unit: {},
              is_total_cost_computed: {},
              total_cost: {},
              margin: {},
              margin_percent: {},
              discount: {},
              tax_ids_after_fiscal_position: {
                fields: {
                  display_name: {},
                  amount: {},
                },
              },
              tax_ids: {},
              price_subtotal: {},
              price_subtotal_incl: {},
              currency_id: {
                fields: {
                  display_name: {},
                },
              },
              refunded_qty: {},
              notice: {},
            },
            limit: 40,
            order: '',
          },

          amount_total: {},
          amount_paid: {},
          margin: {},
          margin_percent: {},
          is_total_cost_computed: {},
          currency_id: {
            fields: {
              display_name: {},
            },
          },
          payment_ids: {
            fields: {
              currency_id: {
                fields: {
                  display_name: {},
                },
              },
              payment_date: {},
              payment_method_id: {
                fields: {
                  display_name: {},
                },
              },
              amount: {},
            },
            limit: 40,
            order: '',
          },
          session_move_id: {
            fields: {
              display_name: {},
            },
          },
          pos_reference: {},
          tracking_number: {},
          country_code: {},
          note: {},
          display_name: {},
        },
      },
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },

  updateProductInOrder: async (productsData) => {
    const body = {
      model: 'pos.order.line',
      values: productsData,
    }
    return requests.post(createPath, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  updateProductAmountInOrder: async (posID, productsData) => {
    const body = {
      model: 'pos.order.line',
      domain: [['id', '=', posID + '']],
      values: productsData,
    }
    return requests.post(updatePath, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  newOrder: async (orderData) => {
    const body = {
      model: 'pos.order',
      values: orderData,
    }
    return requests.post(createPath, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  deleteProductInOrder: async (ids) => {
    const body = {
      model: 'pos.order.line',
      ids: ids,
      method: 'unlink',
    }
    return requests.post(callPath, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  deleteOrder: async (ids) => {
    const body = {
      model: 'pos.order',
      ids: ids,
      method: 'unlink',
    }
    return requests.post(callPath, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  createCustomer: async (body) => {
    return requests.post(createPath, body, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },
  makePayment: async (data) => {
    const body = {
      model: 'pos.payment',
      values: data,
    }
    return requests.post(createPath, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  updateOrder: async (order_id, values) => {
    const body = {
      model: 'pos.order',
      domain: [['id', '=', order_id]],
      values: values,
    }
    return requests.post(updatePath, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  getPaymentMethod: async (pos_id) => {
    const jsonData = {
      "model": "pos.payment.method",
      "method": "web_search_read",
      "kwargs": {
        "domain": [
          [
            "config_ids",
            "=",
            parseInt(pos_id)
          ]
        ],
        "specification": {
          "name": {}
        }
      }
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  getListPOS: async (context_company) => {
    const jsonData = {
      model: 'pos.config',
      method: 'web_search_read',
      with_context: { allowed_company_ids: context_company },
      kwargs: {
        specification: {
          current_user_id: {
            fields: {
              display_name: {},
            },
          },
          cash_control: {},
          name: {},
          current_session_id: {
            fields: {
              display_name: {},
            },
          },
          current_session_state: {},
          last_session_closing_date: {},
          pos_session_username: {},
          pos_session_state: {},
          pos_session_duration: {},
          module_pos_restaurant: {},
          currency_id: {
            fields: {
              display_name: {},
            },
          },
          number_of_rescue_session: {},
          self_ordering_mode: {},
          last_session_closing_cash: {},
        },
        offset: 0,
        order: '',
        limit: 80,
        count_limit: 10001,
        domain: [],
      },
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  getListPOSById: async (posId) => {
    const jsonData = {
      model: 'pos.config',
      method: 'web_search_read',
      kwargs: {
        domain: [['id', '=', posId]],
        specification: {
          module_pos_restaurant: {},
          name: {},
          floor_ids: {
            fields: {
              name: {},
              table_ids: { fields: { name: {}, seats: {} } },
            },
          },
        },
        offset: 0,
        order: '',
        limit: 80,
        count_limit: 10001,
      },
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  getImageProduct: async (product_id) => {
    const jsonData = {
      model: 'product.product',
      fields: ['image_128'],
      limit: 10,
      offset: 0,
      domain: [['id', '=', product_id]],
    }
    return requests.post(searchReadPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },

  createNewSession: async (posId) => {
    const body = {
      model: 'pos.session',
      values: {
        config_id: parseInt(posId),
      },
    }
    return requests.post(createPath, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },

  setCashbox: async (data) => {
    const body = {
      model: 'pos.session',
      ids: data.session_id,
      method: 'set_cashbox_pos',
      kwargs: {
        cashbox_value: parseInt(data.cashbox_value),
        notes: data.notes,
      },
    }
    return requests.post(callPath, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  closeSession: async (data) => {
    const body = {
      model: 'pos.session',
      ids: parseInt(data.session_id),
      method: 'action_pos_session_close',
    }
    return requests.post(callPath, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },

  updateSessionCloseDate: async (data) => {
    const body = {
      model: 'pos.session',
      domain: [['id', '=', parseInt(data.session_id)]],
      values: {
        stop_at: data.stop_at,
        state: data.state,
      },
    }

    return requests.post(updatePath, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },

  createFloor: async (data) => {
    const body = {
      model: 'restaurant.floor',
      values: data,
    }
    return requests.post(createPath, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },

  createTableInFloor: async (data) => {
    const body = {
      model: 'restaurant.table',
      values: data,
    }
    return requests.post(createPath, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },

  updateTable: async (values) => {
    const body = {
      model: 'restaurant.table',
      domain: [['id', '=', values.table_id]],
      values: { name: values.name },
    }
    return requests.post(updatePath, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },

  updateFloor: async (values) => {
    const body = {
      model: 'restaurant.floor',
      domain: [['id', '=', values.floor_id]],
      values: { name: values.name },
    }
    return requests.post(updatePath, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },

  deleteFloor: async (floorId) => {
    const body = {
      model: 'restaurant.floor',
      domain: [['id', '=', floorId]],
      values: { active: false },
    }
    return requests.post(updatePath, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },



  deleteTable: async (ids) => {
    const body = {
      model: 'restaurant.table',
      domain: [['id', '=', ids]],
      values: { active: false },
    }
    return requests.post(updatePath, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
}

export default POSService
