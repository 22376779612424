/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import alertify from 'alertifyjs'
import POSService from '../../core/services/POSService'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
const PosCreateSession = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  let posId = props.posId
  const [newSessionForm, setNewSessionForm] = useState({
    session_id: 0,
    cashbox_value: 0,
    notes: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setNewSessionForm((prev) => ({ ...prev, [name]: value }))
  }

  const handleCreateNewSession = (posId) => {
    alertify.success(t('create_session_success'))

    console.log('newSessionForm', newSessionForm)
    POSService.createNewSession(posId).then(
      (res) => {
        newSessionForm.session_id = res[0]
        POSService.setCashbox(newSessionForm).then((res) => {
          navigate(`/pos/${posId}`)
        })
        const bodyNewOrder = {
          session_id: res[0],
          amount_tax: 0,
          amount_total: 0,
          amount_paid: 0,
          amount_return: 0,
        }
        POSService.newOrder(bodyNewOrder)
      },
      (err) => {
        console.log('err: ', err)
      }
    )
  }

  return (
    <div className='modal fade' id='create-new-session'>
      <div className='modal-dialog sales-details-modal'>
        <div className='modal-content'>
          <div className='page-wrapper-pos p-0'>
            <div className='content'>
              <div className='modal-header custom-modal-header border-0'>
                <div className='page-title'>
                  <h4>{t('create_new_session')}</h4>
                </div>

                <button type='button' className='close' data-bs-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>×</span>
                </button>
              </div>
              <div className='modal-body custom-modal-body'>
                <div className='modal-title-head people-cust-avatar'>
                  <h6>{t('new_session')}</h6>
                </div>
                <div className='new-employee-field'></div>
                <div className='row'>
                  <div className='col-lg-12 pe-0'>
                    <div className='form-group mb3'>
                      <label className='mb-2'>{t('opening_cash')}</label>
                      <input
                        className='form-control form-control-lg group_formcontrol'
                        id='phone'
                        name='cashbox_value'
                        type='number'
                        value={newSessionForm.cashbox_value}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className='col-lg-12 pe-0'>
                    <div className='form-group mb3'>
                      <label className='mb-2'>{t('opening_note')}</label>
                      <input
                        className='form-control form-control-lg group_formcontrol'
                        id='phone'
                        name='notes'
                        type='text'
                        value={newSessionForm.notes}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className='modal-footer-btn'>
                  <button type='button' className='btn btn-cancel me-2' data-bs-dismiss='modal'>
                    {t('cancel')}
                  </button>
                  <button
                    type='button'
                    className='btn btn-submit'
                    onClick={() => handleCreateNewSession(posId)}
                    data-bs-dismiss='modal'
                  >
                    {t('new_session')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PosCreateSession
