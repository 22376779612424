/* eslint-disable no-unused-vars */
import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import POSService from '../../core/services/POSService'
import RestaurantService from '../../core/services/RestaurantService'
import { formatPrice } from '../../core/utils/formatter'
import { useNavigate } from 'react-router-dom'
import PosCreateSession from './posCreateSession'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Nav, Tab } from 'react-bootstrap'
function PosSession() {
  const { t } = useTranslation()
  const [listPOS, setListPOS] = useState([])
  const [listKitchen, setListKitchen] = useState([])
  const context_company_redux = useSelector((state) => state.context_company)
  const [context_company, setContext_company] = useState([])
  const [posId, setPosId] = useState({})
  const [activeKey, setActiveKey] = useState('pos')
  useEffect(() => {
    const fetchData = async () => {
      setContext_company(context_company_redux)
      const [posListData] = await Promise.all([POSService.getListPOS(context_company_redux)])
      if (posListData?.length > 0 && posListData?.records && posListData?.records?.length > 0) {
        setListPOS(posListData.records)
      }

      const [kitchenListData] = await Promise.all([RestaurantService.getAllPreparationDisplay(context_company_redux)])
      if (kitchenListData?.length > 0 && kitchenListData?.records && kitchenListData?.records?.length > 0) {
        setListKitchen(kitchenListData.records)
      }
    }

    fetchData()
  }, [context_company_redux, context_company])

  console.log(listKitchen, 'listKitchen')
  console.log(listPOS, 'listPOS')

  const navigate = useNavigate()

  const handleNavigatePOS = (posId) => {
    navigate(`/pos/${posId}`)
  }

  const handleData = (posId) => {
    setPosId(posId)
  }

  const handleNavigateKitchen = (kitchenId) => {
    navigate(`/kitchen/${kitchenId}`)
  }

  const POSContent = () => (
    <div
      style={{
        width: '100%',
        justifyContent: 'flex-start',
      }}
      className='row'
    >
      {listPOS?.length > 0 &&
        listPOS.map((pos) => (
          <div key={pos.id} className='col-sm-4 col-md-6 col-lg-6 col-xl-6'>
            <div
              className='product-info default-cover card'
              style={{
                padding: '16px',
                border: '1px solid #dee2e6',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  marginBottom: '16px',
                }}
              >
                <div>
                  <div style={{ fontWeight: 'bold', fontSize: '20px' }}>{pos?.name}</div>
                  {/* <div>To Close</div> */}
                </div>

                {/* <div style={{ cursor: 'pointer' }}>
                  <MoreVertical />
                </div> */}
              </div>

              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>
                  <Button
                    className='btn-submit'
                    type='primary'
                    onClick={() => (pos.pos_session_state ? handleNavigatePOS(pos.id) : handleData(pos.id))}
                    {...(pos.pos_session_state
                      ? {}
                      : {
                        'data-bs-toggle': 'modal',
                        'data-bs-target': '#create-new-session',
                      })}
                  >
                    {' '}
                    {pos.pos_session_state ? t('continue_selling') : t('new_session')}
                  </Button>
                </div>

                <div
                  style={{
                    display: 'flex',
                    width: '40%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <div>{t('closing_date')}</div>
                    <div>{t('balance')}</div>
                  </div>

                  <div>
                    <div>{pos?.last_session_closing_date}</div>
                    <div>{formatPrice(pos?.number_of_rescue_session, pos.currency_id.display_name)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  )

  const KitchenContent = () => (
    <div
      style={{
        width: '100%',
        justifyContent: 'flex-start',
      }}
      className='row'
    >
      {listKitchen?.length > 0 &&
        listKitchen.map((kitchen) => (
          <div key={kitchen.id} className='col-sm-4 col-md-6 col-lg-6 col-xl-6'>
            <div
              className='product-info default-cover card'
              style={{
                padding: '16px',
                border: '1px solid #dee2e6',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  marginBottom: '16px',
                }}
              >
                <div>
                  <div style={{ fontWeight: 'bold', fontSize: '20px' }}>{kitchen?.display_name}</div>
                  {/* <div>To Close</div> */}
                </div>

                {/* <div style={{ cursor: 'pointer' }}>
                  <MoreVertical />
                </div> */}
              </div>

              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>
                  <Button className='btn-submit' type='primary' onClick={() => handleNavigateKitchen(kitchen.id)}>
                    {t('open')}
                  </Button>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '40%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <div>{t('total_orders_count')}</div>
                    <div>{t('point_of_sale')}</div>
                  </div>

                  <div>
                    <div>{kitchen?.order_count}</div>
                    <div>{kitchen?.pos_config_ids[0]?.name}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  )

  return (
    <div className='page-wrapper fade-in'>
      <div className='content'>
        <Tab.Container activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
          <Nav variant='tabs' className='mb-4'>
            <Nav.Item>
              <Nav.Link eventKey='pos'>{t('pos_session_list')}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey='kitchen'>{t('list_kitchen_display')}</Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey='pos'>
              <POSContent />
            </Tab.Pane>
            <Tab.Pane eventKey='kitchen'>
              <KitchenContent />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>

      <PosCreateSession posId={posId} />
    </div>
  )
}

export default PosSession
