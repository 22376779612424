import React, { useCallback, useEffect, useMemo, useState } from 'react'
import TableHead from './table-head'
import TableBody from './table-body'
import TableFooter from './table-footer'
import ResizableHeader from './ResizableHeader'

const TableView = ({
  dataSource,
  columns,
  visibleOptionalFields,
  model,
  isForm = false,
  funcAddLine,
  rootField,
  dataLength,
  funcRemoveLine,
  handleOnchange,
  viewData,
  resModel,
  tabIndex,
  type,
  append
}) => {
  const initialColumnWidths = useMemo(() => columns?.map(() => 50), [columns])
  const [columnWidths, setColumnWidths] = useState(initialColumnWidths)

  useEffect(() => {
    setColumnWidths(initialColumnWidths)
  }, [columns, initialColumnWidths])

  const handleResize = useCallback(
    (index) =>
      (e, { size }) => {
        setColumnWidths((prev) => {
          const newWidths = [...prev]
          newWidths[index] = size.width
          return newWidths
        })
      },
    []
  )
  const memoizedColumnWidths = useMemo(() => columnWidths, [columnWidths])
  return (
    <div className='w-full max-w-full overflow-auto'>
      {memoizedColumnWidths && (
        <table className='relative w-full bg-white'>
          <TableHead
            model={model}
            isForm={isForm}
            columns={columns}
            dataSource={dataSource}
            visibleOptionalFields={visibleOptionalFields}
            columnWidths={memoizedColumnWidths}
            handleResize={handleResize}
            ResizableHeader={ResizableHeader}
            tabIndex={tabIndex}
            type={type}
          />
          <TableBody
            viewData={viewData}
            resModel={resModel}
            model={model}
            columns={columns}
            isForm={isForm}
            dataSource={dataSource}
            rootField={rootField}
            dataLength={dataLength}
            columnWidths={memoizedColumnWidths}
            funcRemoveLine={funcRemoveLine}
            handleOnchange={handleOnchange}
            visibleOptionalFields={visibleOptionalFields}
            append={append}
            type={type}
          />
          {isForm && <TableFooter columns={columns} funcAddLine={funcAddLine} />}
        </table>
      )}
    </div>
  )
}

export default TableView
