import React, { useState, useEffect } from "react";
import ImageWithBasePath from "../general/ImageCustom";
import { useSelector, useDispatch } from "react-redux";
import { set_context_company_data } from "../../core/utils/redux/action";
import StoreService from "../../core/services/company/StoreService";
import useAsync from "../../hooks/useAsync";
import { Link } from "react-router-dom";
import Loader from "react-js-loader";
const SelectStore = () => {
  const dispatch = useDispatch();
  const context = useSelector((state) => state.context_company);

  // STORE SERVICE
  const [stores, setStores] = useState([]);

  const [currentStore, setCurrentStore] = useState({});

  const [selectedStoreList, setSelectedStoreList] = useState([]);

  const { data } = useAsync(
    () => StoreService.getAllowedStore(),
    "allowed-store-list"
  );



  const handleSwitchCompany = async (store) => {
    setSelectedStoreList((selectedStoreList) => {
      const storeExists = selectedStoreList.some(
        (selectedStore) => selectedStore.id === store.id
      );
      if (storeExists) {
        // Remove the store if it exists
        return selectedStoreList.filter(
          (selectedStore) => selectedStore.id !== store.id
        );
      } else {
        // Add the store if it doesn't exist
        return [...selectedStoreList, store];
      }
    });
  };

  const handleSelectAllStore = async () => {
    const allStores = data?.records || [];
    const storedCompanyIds = context || [];
    const newCompanyIds = allStores.map((item) => item.id);

    // Check if all new company ids are already stored
    const AllInContext = newCompanyIds.every((id) =>
      storedCompanyIds.includes(id)
    );

    if (AllInContext) {
      dispatch(
        set_context_company_data(selectedStoreList.map((item) => item.id))
      );
    } else {
      dispatch(set_context_company_data(newCompanyIds));
    }
  };

  const fetchCurrentCompany = async () => {
    await StoreService.getUserCompanyByUserId().then((res) => {
      setCurrentStore(res.records[0]?.company_id);
      setStores(res.records[0]?.company_ids);
      const checkbox = document.getElementById(
        `checkbox-${res.records[0]?.company_id.id}`
      );
      if (checkbox) {
        checkbox.checked = true;
      }
      if (selectedStoreList.length === 0) {
        setSelectedStoreList([res.records[0]?.company_id]);
        dispatch(set_context_company_data([res.records[0]?.company_id.id]));
      }
      if (selectedStoreList) {
        dispatch(
          set_context_company_data(selectedStoreList.map((item) => item.id))
        );
      }
    });
  };

  useEffect(() => {
    fetchCurrentCompany();
  }, [data, selectedStoreList]);

  return (
    <>
      <Link
        to="#"
        className="dropdown-toggle nav-link select-store"
        data-bs-toggle="dropdown"
      >
        <span className="user-info">

          <span className="user-letter">
            {currentStore?.logo ? <ImageWithBasePath
              src={`${currentStore.logo || 'assets/img/icons/company.svg'}`}
              alt="Store Logo"
              className="img-fluid"
              width={20}
              height={20}
            /> : <Loader
              type="ping-cube"
              color="#ED1C24"
              bgColor="#ED1C24"
              size={30}
            />}
          </span>
          <span className="user-detail">
            <span className="user-name">{currentStore.name}</span>
          </span>

        </span>
      </Link>

      <div className="dropdown-menu dropdown-menu-right">
        {stores?.map((item) => {
          return (
            <div key={item.id} className="dropdown-item">
              <input
                type="checkbox"
                id={`checkbox-${item.id}`}
                value={item.id}
                onChange={() => handleSwitchCompany(item)}
              />
              <label htmlFor={`checkbox-${item.id}`} className="dropdown-item">
                {item.name}
              </label>
            </div>
          );
        })}

        <div className="dropdown-item">
          <input
            type="checkbox"
            id={"select_all"}
            onClick={handleSelectAllStore}
          />
          <label htmlFor={"select_all"} className="dropdown-item">
            All{" "}
          </label>
        </div>
      </div >
    </>
  );
};

export default SelectStore;
