import React, { useState } from "react";
import ImageWithBasePath from "../general/ImageCustom";
import { useClickOutside } from "../../hooks/useClickOutside";
import { useTranslation } from "react-i18next";
import { CookieStorage } from "../../util/lib/Cookies";
import { LANG } from "../../util/constant/lang";

const SwitchLanguage = () => {
  const languages = [
    {
      name: "VI",
      code: "vi_VN",
    },
    {
      name: "ENG",
      code: "en_US",
    },
  ];
  const [toggleDropDownLanguages, setToggleDropDownLanguages] = useState(false);
  const { i18n } = useTranslation();
  const lang = CookieStorage.getLanguageContext() || LANG.VI_VN
  const switchLanguageRef = useClickOutside(() => {
    setToggleDropDownLanguages(false);
  });

  const handleLanguageChange = (lang) => {
    const code = lang?.code;
    const codeLangLowerCase = String(code).split("_")[0];
    CookieStorage.setLanguage(codeLangLowerCase);
    CookieStorage.setLanguageContext(code);
    i18n.changeLanguage(codeLangLowerCase);
    window.location.reload();
  };

  return (
    <div
      ref={switchLanguageRef}
      onClick={() => setToggleDropDownLanguages(!toggleDropDownLanguages)}
      className="flex items-center gap-2 py-1 px-3 rounded-lg border border-gray-primary cursor-pointer relative"
    >
      <span className="text-xs leading-5 font-bold">
        {languages.find((item) => item.code === lang)?.name}
      </span>
      <ImageWithBasePath
        src="assets/img/header/chevrondown.svg"
        alt="down"
        width={16}
        height={16}
      />
      {toggleDropDownLanguages && (
        <div className="absolute left-0 z-50 w-full rounded-lg border border-gray-primary bg-white top-[calc(100%_+_4px)]">
          {languages.map((lang, index) => (
            <div
              key={`lang-${index}`}
              onClick={() => handleLanguageChange(lang)}
              className="language-item text-xs leading-5 font-bold select-item hover:bg-[#08813C10] px-3 py-1 transition-all"
            >
              {lang.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SwitchLanguage;
