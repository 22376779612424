/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Route,
  Routes,
  useNavigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import { pagesRoute, publicRoutes, posRoutes } from "./router.link";
import { useSelector } from "react-redux";
import { CookieStorage } from "../util/lib/Cookies";
import Header from "../components/header";
import ThemeSettings from "../components/header/ThemeSettings";
import MetadataManager from "../metadata/MetadataManager";
import StoreService from "../core/services/company/StoreService";
import { MetaProvider } from "../core/utils/context/MetaContext";
import "../style/css/color.css";
import { useTranslation } from "react-i18next";
import Sidebar from "../components/header/SideBar";
import { LANG } from "../util/constant/lang";

// const HeaderLayout = () => {
//   const data = useSelector((state) => state.toggle_header);
//   const menuFocus = useSelector((state) => state.menu_focus);
//   const { openTouch, hover } = useMetaContext();

//   return (
//     <div className={`main-wrapper ${data ? "header-collapse" : ""} flex`}>
//       <div
//         className={`transitionSb relative h-full min-h-screen ${openTouch || hover
//           ? "w-[260px] max-w-[260px] duration-700"
//           : "w-[80px] duration-500"
//           } transition-[width] ease-in-out`}
//       >
//         <div
//           className={`trasitionSb fixed left-0 top-0 z-40 h-[100vh] transition-all duration-700 ease-in-out ${openTouch || hover ? "duration-900 w-[260px] transition-all ease-in-out" : "w-[80px]"}`}
//         >
//           <Sidebar />
//         </div>
//       </div>
//       <div
//         className={`grow overflow-hidden ${openTouch || hover ? "max-w-[calc(100%-260px)]" : "max-w-[calc(100%-80px)]"}`}
//       >
//         <Header />
//         <Outlet key={menuFocus} />
//         <ThemeSettings />
//       </div>
//     </div>
//   );
// };

const HeaderLayout = () => {
  const data = useSelector((state) => state.toggle_header);
  return (<div className={`main-wrapper ${data ? "header-collapse" : ""}`}>
    {/* <Loader /> */}
    <Header />
    <Sidebar />
    <Outlet />
    <ThemeSettings />
  </div>)
};


const AllRoutes = () => {
  const data = useSelector((state) => state.toggle_header);
  const location = useLocation();
  console.log("🚀 ~ AllRoutes ~ location:", location.key)
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const lang = CookieStorage.getLanguage() || LANG.VI
  const langContext = CookieStorage.getLanguageContext() || LANG.VI_VN
  useEffect(() => {
    CookieStorage.setLanguage(lang);
    CookieStorage.setLanguageContext(langContext);
    i18n.changeLanguage(lang);

    const hasAccessToken = () => {
      const token = CookieStorage.getAccessToken();
      return !!token;
    };

    if (
      !hasAccessToken() &&
      location.pathname !== "/forgot-password" &&
      location.pathname !== "/reset-password"
    ) {
      navigate("/signin");
    } else {
      if (location.pathname === "/") {
        navigate("/sales-dashboard");
      }
    }
  }, []);

  const Authpages = () => (
    <div className={`${data ? "header-collapse" : ""}`}>
      <Outlet />
      {/* <Loader /> */}
      <ThemeSettings />
    </div>
  );
  const [id, setId] = useState(null);
  const [metadata, setMetadata] = useState(null);
  useEffect(() => {
    console.log("metadata", metadata);
    if (metadata && metadata.primary_color) {
      console.log(
        "document.documentElement.style.setProperty('--primary', metadata.primary_color);",
        // document.documentElement.style.setProperty('--primary', metadata.primary_color)
        document.documentElement.style.setProperty("--primary", "#ed1c24")
      );
      const primaryColor = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--primary");
    } else {
      // Set a default color if primary_color is not available
      // document.documentElement.style.setProperty("--primary", "#000000"); // Example fallback color
    }
  }, [metadata]);

  const [loadingImport, setLoadingImport] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorData, setErrorData] = useState();
  const [openTouch, setOpenTouch] = useState(true);
  const [hover, setHover] = useState(false);
  const accessToken = CookieStorage.getAccessToken();
  const fetchCurrentCompany = async () => {
    await StoreService.getCurrentCompany().then((res) => {
      setId(res?.current_company_id);
    });
  };
  const handleLoadingImport = (action) => {
    setLoadingImport(action);
  };
  const getUserCompany = async (companyId) => {
    await StoreService.getUserCompany(companyId).then((res) => {
      setMetadata(res?.records[0]);
    });
  };
  useEffect(() => {
    accessToken && fetchCurrentCompany();
    if (id && accessToken) {
      getUserCompany(id);
    }
  }, [id, accessToken]);

  const PosPages = () => (
    <div>
      <Outlet />
      {/* <Loader /> */}
      <ThemeSettings />
    </div>
  );

  return (
    <div className="App">
      <MetaProvider
        metadata={metadata}
        errorData={errorData}
        setErrorData={setErrorData}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        loadingImport={loadingImport}
        setLoadingImport={handleLoadingImport}
        openTouch={openTouch}
        setOpenTouch={setOpenTouch}
        setHover={setHover}
        hover={hover}
      >
        {metadata && <MetadataManager metadata={metadata} />}
        <Routes>
          <Route path="/" element={<PosPages />}>
            {posRoutes.map((route, id) => (
              <Route path={route.path} element={route.element} key={id} />
            ))}
          </Route>

          <Route path={"/"} element={<HeaderLayout />}>
            {pagesRoute.map((route, id) => (
              <Route path={route.path} element={route.element} key={location.key} />
            ))}
          </Route>
          <Route path={"/"} element={<Authpages />}>
            {publicRoutes.map((route, id) => (
              <Route path={route.path} element={route.element} key={location.key} />
            ))}
          </Route>
        </Routes>
      </MetaProvider>
    </div>
  );
};
export default AllRoutes;
