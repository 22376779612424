/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-no-undef */
import React from 'react'
import { Navigate, Route } from 'react-router-dom'
import { all_routes } from './all_routes'
import Signin from '../pages/sign-in/page'
import Error404 from '../pages/error-404/page'
import Error500 from '../pages/error-500/page'
import TreeView from '../pages/tree-view/page'
import FormView from '../pages/form-view/page'
import ForgotPassword from '../pages/forgot-password/page'
import ResetPassword from '../pages/reset-password/page'
import Profile from '../pages/profile/page'
import Pos from '../feature-module/sales/pos'
import PosSession from '../feature-module/sales/posSession'
import SalesDashboard from '../feature-module/dashboard/salesdashboard'
import RestaurantTable from '../feature-module/sales/restaurant'
import Kitchen from '../feature-module/sales/kitchen'
const routes = all_routes

export const publicRoutes = [
  {
    id: 0,
    path: routes.signin,
    name: 'Root',
    element: <Signin />,
    route: Route,
  },
  {
    id: 1,
    path: routes.forgotpassword,
    name: 'Root',
    element: <ForgotPassword />,
    route: Route,
  },
  {
    id: 2,
    path: routes.resetpassword,
    name: 'Root',
    element: <ResetPassword />,
    route: Route,
  },
  {
    id: 3,
    path: '*',
    name: 'NotFound',
    element: <Navigate to='/' />,
    route: Route,
  },
]

export const pagesRoute = [
  {
    id: 4,
    path: '',
    name: 'tree-view',
    element: <TreeView />,
    route: Route,
  },
  {
    id: 5,
    path: '/list',
    name: 'tree-view',
    element: <TreeView />,
    route: Route,
  },
  {
    id: 6,
    path: '/form',
    name: 'form-view',
    element: <FormView />,
    route: Route,
  },
  {
    id: 7,
    path: routes.error404,
    name: 'error404',
    element: <Error404 />,
    route: Route,
  },
  {
    id: 8,
    path: routes.error500,
    name: 'error500',
    element: <Error500 />,
    route: Route,
  },
  {
    id: 9,
    path: routes.profile,
    name: 'profile',
    element: <Profile />,
    route: Route,
  },
  {
    id: 10,
    path: routes.possession,
    name: 'pos-session',
    element: <PosSession />,
    route: Route,
  },
  {
    id: 11,
    path: routes.salesdashboard,
    name: 'dashboard',
    element: <SalesDashboard />,
    route: Route,
  },
  {
    id: 11,
    path: routes.posrestaurant,
    name: 'restaurant',
    element: <RestaurantTable />,
    route: Route,
  },

]

export const posRoutes = [
  {
    id: 1,
    path: routes.pos,
    name: 'pos',
    element: <Pos />,
    route: Route,
  },
  {
    id: 12,
    path: routes.kitchen,
    name: 'kitchen',
    element: < Kitchen />,
    route: Route,
  }
]
