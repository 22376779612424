import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import POSService from '../../core/services/POSService'
import ConfirmModal from '../components/modal/ConfirmModal'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { formatPrice } from '../../core/utils/formatter'
import { useNavigate } from 'react-router-dom'
const CustomTab = ({
  orderBySession,
  sessionId,
  activeKeyPos,
  showCompletePayment,
  openModal,
  keyDelete,
  orderList,
  orderDetail,
  paymentMethod,
  selectedTable,
  methodSelect,
  showChooseMethod,
  posId,
  handleCompletePayment,
  handleCloseCompletePayment,
  handleOpenPrintReceipt,
  handleCloseChooseMethod,
  handleChooseMethod,
  setOrderList,
  setOrderDetail,
  setLoadingOrderDetail,
  setOpenModal,
}) => {
  const { t } = useTranslation()
  const [activeKey, setActiveKey] = useState(activeKeyPos)
  const [items, setItems] = useState([])
  const [hiddenTabIds, setHiddenTabIds] = useState([]) // Track hidden paid invoices
  const navigate = useNavigate()
  useEffect(() => {
    if (orderBySession.length > 0 && !selectedTable) {
      const paidInvoiceIds = orderBySession?.records
        ?.filter((invoice) => invoice.state === 'paid')
        .map((invoice) => invoice.id)
      setHiddenTabIds(paidInvoiceIds)
      const temp = orderBySession?.records
        ?.filter((invoice) => invoice.state === 'draft')
        .map((invoice) => ({ key: invoice.id, label: `${t('invoice')} ${invoice.id} ` }))
      setActiveKey(temp[0]?.key)
      onChange(temp[0]?.key)
      setItems(temp)
    }
    if (activeKeyPos) {
      setActiveKey(activeKeyPos)
    }
  }, [orderBySession, activeKeyPos])

  useEffect(() => {
    const temp = orderBySession?.records
      ?.filter((invoice) => !hiddenTabIds.includes(invoice.id))
      .map((invoice) => ({
        key: invoice.id,
        label: `${t('invoice')} ${invoice.id} `,
      }))
    setItems(temp)
  }, [hiddenTabIds, setHiddenTabIds])

  const onChange = async (newActiveKey) => {
    setActiveKey(newActiveKey)
    setLoadingOrderDetail(true)
    const orderDetail = await POSService.getOrderDetail(newActiveKey)
    if (orderDetail.length > 0 && orderDetail[0]?.lines && orderDetail[0].lines?.length >= 0) {
      const { lines, ...rest } = orderDetail[0]
      setOrderList(lines)
      setOrderDetail(rest)
    }
    setLoadingOrderDetail(false)
  }

  // Show a specific invoice tab
  const showTabById = (id) => {
    setHiddenTabIds((prev) => prev.filter((tabId) => tabId !== id))
  }

  const addNewOrder = async () => {
    const bodyNewOrder = {
      session_id: sessionId,
      amount_tax: 0,
      amount_total: 0,
      amount_paid: 0,
      amount_return: 0,
    }

    if (selectedTable) {
      bodyNewOrder.table_id = selectedTable
    }

    const res = await POSService.newOrder(bodyNewOrder)
    const newActiveKey = res[0]
    const newPanes = [...items]
    await onChange(newActiveKey)
    newPanes.push({
      label: `Invoice ${newActiveKey}`,
      key: newActiveKey,
    })
    setItems(newPanes)
    setActiveKey(newActiveKey)
  }

  const remove = async (targetKey) => {
    let newActiveKey = activeKey
    let lastIndex = -1
    items.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1
      }
    })
    const newPanes = items.filter((item) => item.key !== targetKey)
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key
      } else {
        newActiveKey = newPanes[0].key
      }
    }

    setItems(newPanes)
    setActiveKey(newActiveKey)
    onChange(newActiveKey)
  }

  const handleViewOrder = async (orderId) => {
    onChange(orderId)
  }

  console.log(orderBySession)

  const onEdit = async (targetKey, action) => {
    if (action === 'add') {
      addNewOrder()
    } else {
      remove(targetKey)
    }
  }

  const confirmModal = async () => {
    await POSService.deleteOrder(keyDelete)
    remove(keyDelete)
    setOpenModal(false)
    if (selectedTable) {
      navigate(`/pos/${posId}`)
    }
  }

  return (
    <>
      {!selectedTable ? (
        <Tabs
          style={{ marginTop: '10px' }}
          type='editable-card'
          onChange={onChange}
          activeKey={activeKey}
          onEdit={onEdit}
          items={items}
        />
      ) : (
        <Link to={'#'}>
          <div className='info-sp'>
            <span
              style={{
                color: 'white',
                backgroundColor: '#16A84F',
                height: '40px',
                lineHeight: '40px',
                padding: '0 10px',
              }}
            >
              {t('table')} # {orderDetail?.table_id?.display_name}
            </span>
          </div>
        </Link>
      )}

      <ConfirmModal
        title={t('confirm_delete_draft_invoice')}
        content={t('content_delete_draft_invoice')}
        onOkFunc={confirmModal}
        onCancelFunc={() => setOpenModal(false)}
        open={openModal}
        textCancel={t('cancel')}
        textOk={t('Delete')}
      />

      <Modal
        show={showCompletePayment}
        onHide={handleCloseCompletePayment}
        centered
        id='payment-completed'
        aria-labelledby='payment-completed'
      >
        <Modal.Body className='text-center'>
          <form>
            <div className='d-flex justify-content-between'>
              <h4>{t('complete_payment')}</h4>
              <button type='button' className='close' onClick={handleCloseCompletePayment}>
                <span>&times;</span>
              </button>
            </div>
            <div className='modal-footer d-sm-flex justify-content-between'>
              <button type='button' className='btn btn-primary flex-fill me-1' onClick={handleCloseCompletePayment}>
                {t('next_order')}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <div className='modal fade pos-modal' id='orders' tabIndex={-1} aria-hidden='true'>
        <div className='modal-dialog modal-md modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header p-4'>
              <h5 className='modal-title'>{t('orders')}</h5>
              <button type='button' className='close' data-bs-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true'>×</span>
              </button>
            </div>
            <div className='modal-body p-4'>
              <div className='tabs-sets'>
                <h3 style={{ marginBottom: '10px' }}>
                  {t('total_orders')} : {orderBySession?.records?.filter((item) => item.state !== 'draft').length}
                </h3>
                <div className='tab-content'>
                  <div className='order-body'>
                    {orderBySession?.records
                      ?.filter((item) => item.state !== 'draft')
                      .map((item) => (
                        <div className='default-cover mb-4 p-4' key={item?.id}>
                          <span className='badge d-inline-block bold mb-4 bg-primary'>
                            {t('Order')} : # {item?.id}
                          </span>
                          <div className='row'>
                            <div className='col-sm-12 col-md-6 record mb-3'>
                              <table>
                                <tbody>
                                  <tr className='mb-3'>
                                    <td>{t('shop')}</td>
                                    <td>:</td>
                                    <td className='text'>{item?.config_id.display_name}</td>
                                  </tr>
                                  <tr>
                                    <td>{t('customer')}</td>
                                    <td>:</td>
                                    <td className='text'>{item?.partner_id.display_name}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className='col-sm-12 col-md-6 record mb-3'>
                              <table>
                                <tbody>
                                  <tr>
                                    <td>{t('date')}</td>
                                    <td>:</td>
                                    <td className='text'>{item?.date_order}</td>
                                  </tr>
                                  <tr>
                                    <td>{t('state')}</td>
                                    <td>:</td>
                                    <td className='text'>{t(`${item?.state}`)}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className='btn-row d-flex align-items-center justify-content-between'>
                            <Link
                              to='#'
                              className='btn btn-info btn-icon flex-fill'
                              onClick={(e) => {
                                e.preventDefault()
                                showTabById(item?.id)
                                handleViewOrder(item?.id)
                              }}
                              data-bs-dismiss='modal'
                            >
                              {t('open')}
                            </Link>
                            <Link
                              to='#'
                              className='btn btn-success btn-icon flex-fill'
                              onClick={(e) => {
                                e.preventDefault()
                                handleViewOrder(item?.id)
                                handleOpenPrintReceipt()
                              }}
                            >
                              {t('print')}
                            </Link>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* payment method modal */}
      <Modal
        show={showChooseMethod}
        onHide={handleCloseChooseMethod}
        centered
        id='payment-method-modal'
        aria-labelledby='payment-method-modal'
      >
        <Modal.Body>
          <div
            style={{
              display: 'flex',
              gap: '20px',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderBottom: '1px solid #f3f6f9',
              }}
            >
              <div style={{ width: '100%' }} className='block-section'>
                <div className='order-total'>
                  <table className='table-responsive table-borderless table'>
                    <tbody>
                      <tr>
                        <td>{t('provisional')}</td>
                        <td className='text-end'>
                          {' '}
                          {orderList?.length > 0 && orderDetail?.currency_id?.display_name
                            ? formatPrice(
                                orderList?.reduce((sum, item) => sum + item.price_subtotal, 0),
                                orderDetail?.currency_id?.display_name
                              )
                            : '0'}
                        </td>
                      </tr>
                      {/* <tr>
                        <td>Phí giao hàng </td>
                        <td className="text-end">$40.21</td>
                      </tr> */}
                      <tr>
                        <td>{t('tax')}</td>
                        <td className='text-end'>
                          {' '}
                          {orderList?.length > 0 && orderDetail?.currency_id?.display_name
                            ? formatPrice(
                                orderList?.reduce(
                                  (sum, item) => sum + (item.price_subtotal_incl - item.price_subtotal),
                                  0
                                ),
                                orderDetail?.currency_id?.display_name
                              )
                            : '0'}
                        </td>
                      </tr>
                      {/* <tr>
                        <td className="danger">Giảm giá (10%)</td>
                        <td className="danger text-end">$15.21</td>
                      </tr> */}
                      <tr className='font-bold'>
                        <td>{t('total')}</td>
                        <td className='text-end'>
                          {' '}
                          {orderList?.length > 0 && orderDetail?.currency_id?.display_name
                            ? formatPrice(
                                orderList?.reduce((sum, item) => sum + item.price_subtotal_incl, 0),
                                orderDetail?.currency_id?.display_name
                              )
                            : '0'}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className='payment-method-custom block-section payment-method'>
              <h6 className='text-center'>{t('payment_method')}</h6>
              <div className='row d-flex align-items-center justify-content-center methods'>
                {paymentMethod?.length > 0 &&
                  paymentMethod.map((method) => (
                    <div key={method.id} className='col-md-6 col-lg-4 item'>
                      <div
                        onClick={() => handleChooseMethod(method)}
                        className={`default-cover default-cover-custom ${
                          methodSelect && methodSelect?.id === method.id && 'active'
                        }`}
                      >
                        {/* <ImageWithBasePath
                                src="assets/img/icons/cash-pay.svg"
                                alt="Payment Method"
                              /> */}
                        <span>{t(method?.name)}</span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className='modal-footer d-sm-flex justify-content-between'>
              <Link
                onClick={(e) => {
                  handleCompletePayment()
                  addNewOrder()
                  e.preventDefault()
                }}
                to='#'
                className='btn btn-secondary flex-fill'
              >
                {t('checkout')}
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CustomTab
