import React from 'react'
import { X } from 'react-feather'
import useSearchStore from '../../../../store/search'
import useSearch from '../../model/search'

const TagSearch = () => {
    const { selectedTags } = useSearchStore(state => state)
    const { removeSelectedTag } = useSearch()
    return (
        selectedTags?.map((tag, index) => (
            <div key={'selected-tag-' + index} className='flex min-h-full overflow-hidden rounded bg-[#E9ECEF]'>
                <div className='tag-title flex items-center justify-center px-2 text-sm font-semibold leading-[1.5] text-white'>
                    {tag?.title}
                </div>
                <div className='pl-2 align-middle text-[#495057]'>
                    {tag.values.map((value, idx) => (
                        <React.Fragment key={idx}>
                            <span>{value}</span>
                            {idx < tag.values.length - 1 && <span className='text-sm italic text-[#495057] text-opacity-50'> or </span>}
                        </React.Fragment>
                    ))}
                </div>
                <button className='px-2 text-sm font-bold' onClick={() => removeSelectedTag(tag?.title, index)}>
                    <X size={16} />
                </button>
            </div>
        ))
    )
}

export default TagSearch
