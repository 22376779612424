import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import useLoginSubmit from '../../hooks/useLoginSubmit'
import { SidebarContext } from '../../util/context/SidebarContext'
import { CookieStorage } from '../../util/lib/Cookies'
import ImageWithBasePath from '../../components/general/ImageCustom'
import ButtonLoading from '../../components/general/ButtonLoading'

const Signin = () => {
  localStorage.setItem("layoutStyling", "horizontal")
  const [isPasswordVisible, setPasswordVisible] = useState(false)
  const [isRemember, setIsRemember] = useState(true)
  let navigate = useNavigate()
  const { register, handleSubmit } = useForm()
  const { onSubmit } = useLoginSubmit({
    navigate: navigate,
    isRemember: isRemember,
  })

  const { isLoading } = useContext(SidebarContext)

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState)
  }
  const handleRemember = () => {
    !isRemember ? setIsRemember(true) : setIsRemember(false)
  }

  useEffect(() => {
    const hasAccessToken = () => {
      const token = CookieStorage.getAccessToken()
      return !!token
    }
    if (hasAccessToken()) {
      navigate('/sales-dashboard')
    }
  }, [])

  return (
    <div className="w-full h-screen">
      <div className="relative w-full h-full">
        <ImageWithBasePath
          src={'assets/img/authentication/signin-bg.png'}
          className="object-cover w-full h-full"
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="absolute inset-0 flex justify-center items-center"
        >
          <ImageWithBasePath
            src={'assets/img/main-bg.png'}
            className="object-cover w-[548px] h-[576px] hidden md:block"
          />
          <div className="flex flex-col gap-[40px] bg-white p-10 shadow-lg rounded-md w-[479px] h-[576px]">
            <div className='w-full flex justify-center'>
              <ImageWithBasePath
                src={'assets/img/logoLotte.png'}
                className="object-contain  w-[271px] h-auto"
              />
            </div>
            <div className='flex flex-col gap-[24px]'>
              <div className='flex flex-col gap-3'>
                <label className="text-[rgba(38,38,38,1)] font-semibold text-[14px]">
                  Tên đăng nhập
                </label>
                <input type="text"   {...register('email')} className='border-[1px] border-solid w-full border-[rgba(217,217,217,1)] py-[14px] pl-4 text-[14px] font-normal rounded' placeholder='Nhập tên dăng nhập' />
              </div>
              <div className='flex flex-col gap-3'>
                <label className="text-[rgba(38,38,38,1)] font-semibold text-[14px]">
                  Mật khẩu
                </label>
                <div className='relative'>
                  <input type={isPasswordVisible ? 'text' : 'password'}
                    {...register('password')}
                    className='border-[1px] w-full border-solid border-[rgba(217,217,217,1)] py-[14px] pl-4 text-[14px] font-normal rounded' placeholder='Nhập mật khẩu' />
                  <div onClick={togglePasswordVisibility} className='absolute right-[10px] top-1/2 transform -translate-y-1/2'>
                    {!isPasswordVisible ?
                      <ImageWithBasePath
                        src={'assets/img/authentication/hide-password.svg'}
                        className="object-contain w-[20px] h-[20px]"
                      />
                      :
                      <ImageWithBasePath
                        src={'assets/img/authentication/show-password.svg'}
                        className="object-contain w-[20px] h-[20px]"
                      />
                    }
                  </div>
                </div>
              </div>
              <div className='flex justify-between items-center'>
                <div className='flex gap-3 items-center'>
                  <label
                    className={`group cursor-pointer rounded-[4px] ${!isRemember && 'h-[20px] w-[20px] border-[2px] border-[#D9D9D9]'
                      }`}
                  >
                    <div className='flex size-5 items-center justify-center rounded-[4px] transition-all group-has-[:checked]:border-[rgba(15,48,112,1)] group-has-[:checked]:bg-[rgba(15,48,112,1)] group-has-[:checked]:opacity-100'>
                      <ImageWithBasePath
                        src={'assets/img/authentication/tick.svg'}
                        className="object-contain w-[13px] h-[13px]"
                      />
                    </div>
                    <input
                      type='checkbox'
                      onChange={() => handleRemember()}
                      checked={isRemember}
                      width={20}
                      className='hidden cursor-pointer'
                      height={20}
                    />
                  </label>
                  <div className='text-[rgba(15,48,112,1)] font-medium text-[14px]'>Lưu tài khoản</div>
                </div>
                <button type="button" onClick={() => navigate('/forgot-password')} className='underline text-[rgba(15,48,112,1)] font-medium text-[14px]'>Quên mật khẩu?</button>
              </div>
            </div>
            <ButtonLoading loading={isLoading} content={'Đăng nhập'} className='text-[16px] rounded font-semibold py-3 text-[rgba(255,255,255,1)] !bg-[#FF505B]' />
          </div>
        </form>
      </div>
    </div>
  )
}

export default Signin
