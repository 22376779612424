import React, { useMemo } from 'react'
import StatusBar from '../../widget/status-bar/Statusbar'
import { useTranslation } from 'react-i18next'

const ActionsView = ({ loading, nameActionSave, data, viewData, onDelete, id, loadingDelete }) => {
  const { t } = useTranslation()
  const stateData = useMemo(() => {
    return viewData?.models?.['account.move']?.state?.selection || []
  }, [viewData])

  // const [searchParams] = useSearchParams()
  // const model = searchParams.get('model')
  // const idInvoice = searchParams.get('idForm')
  // const [dataForm, setDataForm] = useState(null)
  // const [loading, setLoading] = useState(false)

  // const getInvoiceView = async () => {
  //     if (idInvoice && model) {
  //         const response = await InvoiceService.InvoiceView({ model, idInvoice })
  //         setDataForm(response.value)
  //     }
  // }
  // const handleConfirm = async () => {
  //     if (idInvoice && model) {
  //         const id = Number(idInvoice)
  //         await InvoiceService.ActionConfirm({ model, id })
  //         alert('Confirm success')
  //     }
  // }
  // const handleCreateRecord = async (idInvoice, data) => {
  //     const response = await InvoiceService.CreateRecord({ data, idInvoice })
  //     handleSentEmail(response[0])
  // }
  // const handleSentEmail = async (id) => {
  //     await InvoiceService.SendEmail({ id })
  //     setLoading(false)
  //     alert('Sent email success')
  // }

  // useEffect(() => {
  //     if (dataForm && idInvoice) {
  //         handleCreateRecord(idInvoice, dataForm)
  //     }
  // }, [dataForm])
  return (
    <div className='w-full bg-white rounded-[10px] p-3 md:p-6 border border-[#F2F2F2] flex justify-between'>
      <div>
        <StatusBar statusOptions={stateData} name={''} defaultValue={data?.state} />
      </div>
    </div>
  )
}

export default ActionsView