import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import ButtonLoading from '../../general/ButtonLoading'

const RightToolbar = (props) => {
  const {
    id,
    dataToolbar,
    isInForm,
    onClickBack,
    loading,
    loadingDelete,
    onDelete,
    nameActionSave,
    onClickAddNew,
    onSubmit,
  } = props
  const { t } = useTranslation()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  return (
    <div className='flex items-center gap-[8px] order-3 flex-1 justify-end'>
      <div className='page-btn flex gap-3'>
        {isInForm && (
          <button type='button' className='button-secondary flex items-center gap-1 text-[14px]' onClick={onClickBack}>
            <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
              <path
                d='M9.2045 9.99956L12.917 13.7121L11.8565 14.7726L7.0835 9.99956L11.8565 5.22656L12.917 6.28706L9.2045 9.99956Z'
                fill='#29344a'
              />
            </svg>
            {t('back')}
          </button>
        )}
        {dataToolbar?.root?.create && !isInForm && (
          <button className='button-primary flex items-center gap-1 text-[14px]' onClick={onClickAddNew}>
            <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
              <path
                d='M15 10.625H5C4.65833 10.625 4.375 10.3417 4.375 10C4.375 9.65833 4.65833 9.375 5 9.375H15C15.3417 9.375 15.625 9.65833 15.625 10C15.625 10.3417 15.3417 10.625 15 10.625Z'
                fill='white'
              />
              <path
                d='M10 15.625C9.65833 15.625 9.375 15.3417 9.375 15V5C9.375 4.65833 9.65833 4.375 10 4.375C10.3417 4.375 10.625 4.65833 10.625 5V15C10.625 15.3417 10.3417 15.625 10 15.625Z'
                fill='white'
              />
            </svg>
            {t('add')}
          </button>
        )}
        {isInForm && (
          <>
            <ButtonLoading
              loading={loading}
              className={`button-primary tracking-[-0.5%] ${loading ? 'opacity-50' : 'hover:opacity-85'}`}
              content={nameActionSave}
              func={onSubmit}
            />
            {id ? (
              <ButtonLoading
                func={onDelete}
                type={`button`}
                loading={loadingDelete}
                className={`button-delete tracking-[-0.5%] ${loading ? 'opacity-50' : 'hover:opacity-85'}`}
                content={t('button_delete')}
              />
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default RightToolbar
